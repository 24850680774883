import React, { useCallback, useState, useEffect } from "react";
import { Field } from 'react-final-form'
import AsyncSelect from 'react-select/async';
import { useDataProvider } from 'react-admin';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

const customStyles = {
  control: (provided) => ({
    ...provided,
    minWidth: 170,
    borderRadius: 0,
    border: "1px solid #E5E5E5",
  }),

  placeholder: (provided) => ({
    ...provided,
    opacity: 1,
    color: '#aaa'
  })
}

export default props => {
  const { source, label, resource, record } = props;
  const assignedPropTechId = record && record.propTechDetails && record.propTechDetails.propTech ? record.propTechDetails.propTech : null;

  return (
    <>
      <p className="grey" style={assignedPropTechId != null ? { color: 'red' } : {}}>{label}:</p>
      {assignedPropTechId == null ? (
        <label>
          <Field name={source}>
            {({ input, meta }) => {
              const [currentSelection, setCurrentSelection] = useState({});
              const [open, setOpen] = useState(false);
              const [answeredAlert, setAnsweredAlert] = useState(false);

              const dataProvider = useDataProvider();

              const handleOpen = () => {
                setOpen(true);
              }

              const handleClose = () => {
                setCurrentSelection({});
                setOpen(false);
              }

              const handleNo = () => {
                setCurrentSelection({});
                setOpen(false);
              };
              const handleYes = () => {
                setOpen(false);
                setAnsweredAlert(true);
              };

              const loadOptionsCallback = useCallback(async inputValue => {
                const values = await dataProvider.getList(resource, {
                  filter: { name: inputValue },
                  pagination: {
                    page: 1,
                    perPage: 100,
                  },
                  sort: {
                    field: 'name',
                    order: 'ASC'
                  }
                });
                const options = values.data.map(agent => ({ value: agent.id, label: agent.name }));
                return options;
              }, [dataProvider]);

              useEffect(() => {
                if (currentSelection != null && Object.keys(currentSelection).length !== 0 && assignedPropTechId == null && !answeredAlert && !open) {
                  handleOpen();
                }
              }, [currentSelection, answeredAlert, open])

              const onChange = (value, action) => {
                switch (action.action) {
                  case "select-option": {
                    setCurrentSelection(value);
                    input.onChange(value.value);
                    return;
                  }
                  case "clear": {
                    setCurrentSelection({});
                    input.onChange("");
                    return;
                  }
                  default: { }
                }
              }
              return (<>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  onBackdropClick={handleNo}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">{`Wollen Sie wirklich ${currentSelection != null && Object.keys(currentSelection).length !== 0 ? currentSelection.label : ''} dieses Lead zuweisen?`}</DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      Eine Email wird an {currentSelection != null && Object.keys(currentSelection).length !== 0 ? currentSelection.label : ''} gesendet und das Lead kann nicht mehr bearbeitet werden
                                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleNo} color="primary" autoFocus>
                      Nein
                                    </Button>
                    <Button onClick={handleYes} color="secondary">
                      Ja
                                    </Button>
                  </DialogActions>
                </Dialog>
                <AsyncSelect isClearable={true} isDisabled={assignedPropTechId != null} styles={customStyles} loadOptions={loadOptionsCallback} defaultOptions onChange={onChange} value={currentSelection} />
              </>)
            }}
          </Field>
        </label>
      ) : (
          <p className="error">An Homeday vermittelt</p>
        )}
    </>
  );
}