import React from "react";
import { useHistory } from 'react-router-dom';
import { useCreateController } from 'react-admin';

import CreateLead from './CreateView/Lead';

export default props => {
    const history = useHistory();
    const createProps = useCreateController({ ...props, undoable: false, redirect: 'show' });
    return (
        <div className={'sell details'}>
            <div className="flex space">
                <h2 className="symbol">Lead Anlegen</h2>
                <button onClick={history.goBack} className="btn btn-border"><i className="icon icon-chevron-left"></i>Zurück</button>
            </div>
            <CreateLead {...createProps} />
        </div>
        
    );
}

/*
<div class="content sell details">

                            <div class="flex space">
                                <h2 class="symbol">Immobilienverkauf – Lead</h2>
                                <a href="../../" class="btn btn-border"><i class="icon icon-chevron-left"></i>Zurück</a>
                            </div>


                            <div class="flex row">
                                <div class="flex-item">
                                    <div class="shadow-box overview">
                                        <h3 class="underlined">Übersicht</h3>
                                        <i class="icon icon-pencil"></i>
                                        <div class="details-info">
                                            <p class="grey">Objekt:</p>
                                            <label class="edit">
                                                <input type="text" value="Mehrfamilienhaus">
                        </label>
                                                <p class="grey">Stadt:</p>
                                                <label class="edit">
                                                    <input type="text" value="Hameln">
                        </label>
                                                    <p class="grey">Zimmeranzahl:</p>
                                                    <label class="edit">
                                                        <input type="text" value="9">
                        </label>
                                                        <p class="grey">Größe in m<sup>2</sup>:</p>
                                                        <label class="edit">
                                                            <input type="number" value="245">
                        </label>
                                                            <p class="grey">Preisvorstellung in €:</p>
                                                            <label class="edit">
                                                                <input type="text" value="500.00,00">
                        </label>
                                                                <p class="grey">Preiseinschätzung in €:</p>
                                                                <label class="edit">
                                                                    <input type="text" value="/">
                        </label>

                                                                    <p class="grey">Status des Verkaufs:</p>
                                                                    <div class="right edit">
                                                                        <div class="radio">
                                                                            <input id="active" name="radio-1" type="radio" checked>
                                                                                <label for="active" class="radio-label">aktiv</label>
                            </div>
                                                                            <div class="radio">
                                                                                <input id="inactive" name="radio-1" type="radio">
                                                                                    <label for="inactive" class="radio-label">inaktiv</label>
                            </div>
                                                                                <div class="radio">
                                                                                    <input id="disinterested" name="radio-1" type="radio">
                                                                                        <label for="disinterested" class="radio-label">nicht mehr gewollt</label>
                            </div>
                                                                                </div>

                                                                                <p class="grey">Hochgeladene Dateien:</p>

                                                                                <div class="lightbox-gallery">
                                                                                    <div><img src="/img/haus.jpg" data-image-hd="/img/haus.jpg" alt=""></div>
                                                                                        <div><img src="/img/terrasse.jpg" data-image-hd="/img/terrasse.jpg" alt=""></div>
                                                                                            <div><img src="/img/haus2.jpg" data-image-hd="/img/haus2.jpg" alt=""></div>
                                                                                                <div><img src="/img/terrasse.jpg" data-image-hd="/img/terrasse.jpg" alt=""></div>
                                                                                                </div>

                                                                                                <div class="edit upload">
                                                                                                    <label tabindex="0" for="my-file" class="input-file-trigger">
                                                                                                        <input class="input-file" id="my-file" type="file">
                                                                                                            <i class="icon icon-upload"></i> Weitere Dateien hochladen</label>
                                                                                                        <p class="file-return"></p>
                        </div>
                                                                                                </div>
                                                                                                <div class="flex">
                                                                                                    <a href="https://www.immoverkauf-hameln.de/" class="domain"
                                                                                                        target="_blank">Immoverkauf-hameln.de</a>

                                                                                                    <div class="loader">
                                                                                                        <img src="/img/loader.gif" alt="Ladebalken" title="Lädt" class="loader">
                        </div>
                                                                                                        <input type="submit" class="btn btn-blue hide" value="Speichern">
                    </div>
                                                                                                    </div>

                                                                                                    <div class="shadow-box contact">
                                                                                                        <h3 class="underlined">Kontaktdaten</h3>
                                                                                                        <i class="icon icon-pencil"></i>
                                                                                                        <div class="details-info">
                                                                                                            <p class="grey">Vorname:</p>
                                                                                                            <label class="edit">
                                                                                                                <input type="text" value="Bärbel">
                        </label>
                                                                                                                <p class="grey">Nachname:</p>
                                                                                                                <label class="edit">
                                                                                                                    <input type="text" value="Meier">
                        </label>
                                                                                                                    <p class="grey">E-Mail-Adresse:</p>
                                                                                                                    <label class="edit">
                                                                                                                        <input type="text" value="B.meier@gmx.de">
                        </label>
                                                                                                                        <p class="grey">Telefonnummer:</p>
                                                                                                                        <label class="edit">
                                                                                                                            <input type="text" value="05151 123 45 67">
                        </label>
                    </div>
                                                                                                                        <div class="flex">
                                                                                                                            <p class="utm">UTM: <span>Campaign: wohnungen.de, Medium: cpc, Source: google, Term: wohnung%20verkaufen</span>
                                                                                                                            </p>

                                                                                                                            <div class="loader">
                                                                                                                                <img src="/img/loader.gif" alt="Ladebalken" title="Lädt" class="loader">
                        </div>
                                                                                                                                <input type="submit" class="btn btn-blue hide" value="Speichern">
                    </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div class="flex-item">
                                                                                                                            <div class="shadow-box assign">
                                                                                                                                <i class="icon icon-history" title="Historie"></i>
                                                                                                                                <h3 class="underlined">Makler zuweisen</h3>
                                                                                                                                <div class="details-info">
                                                                                                                                    <p class="grey">Vertriebsmitarbeiter:</p>
                                                                                                                                    <label class="custom-select">
                                                                                                                                        <select>
                                                                                                                                            <option disabled=""></option>
                                                                                                                                            <option>M. Bürst</option>
                                                                                                                                            <option>M. Dwornitschak</option>
                                                                                                                                            <option selected>C. Wiese</option>
                                                                                                                                        </select>
                                                                                                                                    </label>
                                                                                                                                    <p class="grey">Stadt:</p>
                                                                                                                                    <label class="">
                                                                                                                                        <select class="selectbox-search">
                                                                                                                                            <option value="0" placeholder>Makler auswählen</option>
                                                                                                                                            <option value="1">Hapke Immobilien</option>
                                                                                                                                            <option value="2">Immoverkauf Dresden</option>
                                                                                                                                            <option value="3">Immoverkauf Hameln</option>
                                                                                                                                            <option value="4">Immobilienmakler</option>
                                                                                                                                            <option value="5">Immobilienmakler 2</option>
                                                                                                                                            <option value="6">Immobilienmakler 3</option>
                                                                                                                                        </select>
                                                                                                                                    </label>
                                                                                                                                    <form method="post" name="" class="email-text">

                                                                                                                                        <p class="bold">E-Mail:</p>
                                                                                                                                        <p class="grey">Alternative E-Mail-Adresse:</p>
                                                                                                                                        <label class="alternative">
                                                                                                                                            <input type="email">
                            </label>

                                                                                                                                            <textarea class="textarea">Sehr geehrter Herr Hapke, wie besprochen übermittle ich Ihnen den Kunden für den Verkauf eines Einfamilienhauses in Hameln. Ich würde mich freuen, wenn Sie sich melden, sobald Sie Ihren Maklervertrag mit dem Kunden unterschrieben haben. Bei einem Verkauf bitte ich ebenfalls um eine kurze E-Mail, damit ich Ihnen den ausgemachten Anteil von 30 % zzgl. MwSt auf Ihre Provision berechnen kann.

                                                                                                                                            Liebe Grüße
C. Wiese</textarea>
                                                                                                                                            <textarea class="textarea email-footer">ImmoLead 2020 // vertriebler@immolead.de // 05151 123 45 67</textarea>

                                                                                                                                            <div class="flex">
                                                                                                                                                <div class="sold">
                                                                                                                                                    <label data-href="/makler/#invoice">
                                                                                                                                                        <input type="checkbox" name="sold" title="verkauft"
                                                                                                                                                            aria-required="true">Immobilie wurde verkauft
                                    </label>
                                </div>
                                                                                                                                                    <input type="submit" class="btn" value="Nachricht abschicken">
                            </div>
                        </form>
                                                                                                                                            </div>
                                                                                                                                            <div class="history">
                                                                                                                                                <i class="icon icon-clear"></i>
                                                                                                                                                <div>
                                                                                                                                                    <h3>Historie</h3>
                                                                                                                                                    <p>Makler zugewiesen:</p>

                                                                                                                                                    <ol>
                                                                                                                                                        <li>Immoverkauf Dresden</li>
                                                                                                                                                        <li>Hapke Immobilien</li>
                                                                                                                                                    </ol>

                                                                                                                                                </div>
                                                                                                                                            </div>
                </div>

                                                                                                                                        <div class="shadow-box">
                                                                                                                                            <h3 class="underlined">Sonstige Anmerkungen</h3>
                                                                                                                                            <form method="post" name="misc" class="misc">
                                                                                                                                                <textarea class="textarea" placeholder="Notizen"></textarea>
                                                                                                                                                <input type="submit" class="btn btn-blue" value="Speichern">
                    </form>
                </div>
                                                                                                                                        </div>
        </div>

                                                                                                                                    <button class="export">Export</button>
                                                                                                                                    <button class="delete">Lead löschen</button>
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                            <div class="modal" id="delete">
                                                                                                                                <div class="modal-content">
                                                                                                                                    <i class="icon icon-clear"></i>
                                                                                                                                    <h2>Wirklich löschen?</h2>
                                                                                                                                    <hr class="line">
                                                                                                                                        <p>Sind sie sich sicher, dass Sie diesen Datensatz löschen möchten?</p>
                                                                                                                                        <div class="flex">
                                                                                                                                            <button class="btn btn-blue" id="abort">Abbrechen</button>
                                                                                                                                            <button class="btn" id="clear">Endgültig löschen</button>
                                                                                                                                        </div>
    </div>

*/