import React, { useCallback } from "react";

export default props => {
    const { isOverlayActive, setIsOverlayActive, record } = props;
    const hideCallback = useCallback(() => {
        setIsOverlayActive(false);
    }, [setIsOverlayActive]);
    const agents = record?.agentDetails?.previousAgents;
    return (
        <div className={isOverlayActive ? 'history show' : 'history'}>
            <i className="icon icon-clear" onClick={hideCallback}></i>
            <div>
                <h3>Historie</h3>
                <p>Makler zugewiesen:</p>
                {(!agents || !agents?.length) && <span>Keine Einträge</span>}
                <ol>
                    {agents?.length > 0 && agents?.map(agent => <li key={agent.id}>{agent.agent}</li>)}
                </ol>
            </div>
        </div>
    );
}