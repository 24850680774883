import React, { useCallback, useState, useEffect } from "react";
import { Field } from 'react-final-form'
import AsyncSelect from 'react-select/async';
import { useDataProvider } from 'react-admin';
const customStyles = {
    control: (provided) => ({
        ...provided,
        minWidth: 170,
        borderRadius: 0,
        border: "1px solid #E5E5E5",
    }),

    placeholder: (provided) => ({
        ...provided,
        opacity: 1,
        color: '#aaa'
    })
}

export default props => {
    const { isEditMode, source, label, resource } = props;
    const [selectOptions, setSelectOptions] = useState([]);


    return (
        <>
            <p className="grey">{label}:</p>
            {!isEditMode && (
                <label className={"edit"}>
                    <Field name={source} component="input" />
                </label>
            )}
            {isEditMode && (
                <label>
                    <Field name={source}>
                        {({ input, meta }) => {
                            const inputCallback = useCallback(selection => input.onChange(selection.value), [input]);
                            const [currentSelection, setCurrentSelection] = useState({});
                            const dataProvider = useDataProvider();
                            const loadOptionsCallback = useCallback(async inputValue => {
                                const values = await dataProvider.getList(resource, {
                                    filter: { name: inputValue },
                                    pagination: {
                                        page: 1,
                                        perPage: 100,
                                    },
                                    sort: {
                                        field: 'name',
                                        order: 'ASC'
                                    }
                                });
                                const options = values.data.map(agent => ({ value: agent.id, label: agent.name }));
                                setSelectOptions(options);
                                return options;
                            }, [dataProvider]);
                            useEffect(() => {
                                if (!selectOptions)
                                    return;
                                setCurrentSelection(selectOptions.find(s => s.value === input.value));
                            }, [input]);
                            return <AsyncSelect styles={customStyles} loadOptions={loadOptionsCallback} defaultOptions onChange={inputCallback} value={currentSelection} />
                        }}
                    </Field>
                </label>
            )}
        </>
    );
}