import React from "react";

import DetailForm from '../Form/DetailForm';
import TextInputField from '../Form/TextInputField';
import TextAreaInputField from '../Form/TextAreaInputField';
import NumberInputField from '../Form/NumberInputField';
import SelectInputField from '../Form/SelectInputField';
import AutocompleteSelectInputField from '../Form/AutocompleteSelectInputField';
import PostalCodeInputField from '../Form/PostalCodeInputField';
import StatusRadioInputField from '../Form/StatusRadioInputField';
import MailStatus from '../Form/MailStatus';
import Reachability from '../Form/Reachability';
import AgentHistoryOverlay from './AgentHistoryOverlay';

import ContactFooter from './ContactFooter';
import OverviewFooter from './OverviewFooter';
import SubmitButton from '../Form/SubmitButton';

import { objectTypes } from '../LeadsList';
import { statusOptions } from '../LeadsEdit';

import EstimationView from '../Form/EstimationView';

import listingSchema from './schema/LeadSaleListing.json';
import customerSchema from './schema/LeadSaleCostumer.json';
import remarksSchema from './schema/LeadRemarks.json';
import PropTechSelectField from "../PropTechs/PropTechSelectField";

import { useHasPropTech } from '../../utils/useHasPropTech';

export default props => {
    const hasPropTech = useHasPropTech(props.record);

    return (
        <div className="flex row">
            <div className="flex-item">
                <DetailForm
                    formLabel="Übersicht"
                    boxClassName="overview"
                    validationSchema={listingSchema}
                    {...props}
                    noEditMode={hasPropTech}
                >
                    <SelectInputField source="listing.objectType" label="Objekt" options={objectTypes} />
                    <PostalCodeInputField source="listing.postalCode" label="Postleitzahl" />
                    <NumberInputField source="listing.areaFrom" label="Größe von (in m&sup2;)" />
                    <NumberInputField source="listing.areaTo" label="Größe bis (in m&sup2;)" />
                    <NumberInputField source="listing.priceFrom" label="Preis von" currency />
                    <NumberInputField source="listing.priceTo" label="Preis bis" currency/>
                    <StatusRadioInputField source="listing.status" label="Status der Suche" options={statusOptions} />
                    <TextAreaInputField source="listing.message" label="Kunden Nachricht" additionalLabel={true} />
                    <OverviewFooter />
                </DetailForm>
                <DetailForm
                    formLabel="Kontaktdaten"
                    boxClassName="contact"
                    validationSchema={customerSchema}
                    {...props}
                    noEditMode={hasPropTech}
                >
                    <TextInputField source="customer.firstName" label="Vorname" />
                    <TextInputField source="customer.lastName" label="Nachname" />
                    <TextInputField source="customer.email" label="E-Mail-Adresse" />
                    <MailStatus source="customer.verified" label="E-Mail bestätigt" />
                    <TextInputField source="customer.phone" label="Telefonnummer" />
                    <TextInputField source="customer.additionalPhone" label="Weitere Telefonnummer" />
                    <Reachability source="customer.reachability" label="Erreichbarkeit" />
                    <ContactFooter />
                </DetailForm>
            </div>
            <div className="flex-item">
                <DetailForm
                    formLabel="Makler zuweisen"
                    boxClassName="assign"
                    noEditMode
                    overlay={<AgentHistoryOverlay />}
                    {...props}
                >
                    <PropTechSelectField source="propTechDetails.propTech" label="Prop Tech" resource="proptechs" />
                    <SelectInputField source="salesUser" label="Vertriebsmitarbeiter" options={{}} />
                    <AutocompleteSelectInputField source="agentDetails.agent" label="Makler" resource='agents_type_estateAgent' />
                    <SubmitButton />
                </DetailForm>
                <DetailForm
                    formLabel="Ermittelter Durchschnittswert"
                    boxClassName=""
                    noEditMode
                    {...props}
                >
                   <EstimationView />
                </DetailForm>
                <DetailForm
                    formLabel="Sonstige Anmerkungen"
                    boxClassName=""
                    noEditMode
                    validationSchema={remarksSchema}
                    {...props}
                >
                    <TextAreaInputField source="remarks" label="Notizen" />
                    <SubmitButton />
                </DetailForm>
            </div>
        </div>
    );
}