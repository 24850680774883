import React, { useCallback } from "react";
import { Field } from 'react-final-form'

export default props => {
    const { isEditMode, source, label, formatData, disabled = false } = props;
    const formatCallback = useCallback((value) => {
        return formatData[value] ?? value;
    }, [formatData]);
    return (
        <Field name={source} format={formatData ? formatCallback : undefined}>
            {({ input, meta }) => (
                <>
                    <p className="grey">{label}:</p>
                    <label className={"edit" + (isEditMode ? ' active' : '')}>
                        <input {...input} type="text" {...(disabled ? {disabled : true} : {})} />
                        {(meta.error || meta.submitError) && meta.touched && (
                            <span className="error">{meta.error || meta.submitError}</span>
                        )}
                    </label>
                </>
            )}
        </Field>
    );
}