import React, { useCallback } from "react";
import { useHistory } from 'react-router-dom';

import List from './List';
import DataGrid from './List/DataGrid';
import TextField from './List/TextField';
import DateField from './List/DateField';
import WebsiteStatusField from './List/WebsiteStatusField';

import WebsitesInlineEdit from './CreateView/WebsitesInlineEdit';

const statusData = [
    {
        color: 'green',
        label: 'Verifiziert',
        status: [
            'verified'
        ]
    },
    {
        color: 'yellow',
        label: 'Verifizierung läuft',
        status: [
            'inProgress'
        ]
    },
    {
        color: 'red',
        label: 'Fehler',
        status: [
            'failed'
        ]
    },
]

const defaultSort = { field: 'createdAt', order: 'DESC' };

export default (props) => {

    const { basePath } = props;
    const history = useHistory();
    const addNewCallback = useCallback(() => {
        if(!history)
            return;
        history.push(`${basePath}/create`);
    },[history, basePath]);

    return (
            <List {...props} sort={defaultSort} perPage={20} handleAdd={addNewCallback} legendData={statusData} >
                <DataGrid rowClick="expand" expand={WebsitesInlineEdit} expandClass="edit-broker show" tableClassName="broker-table">
                    <WebsiteStatusField source="status" label="" notSearchable statusData={statusData} />
                    <TextField source="domain" label="Domain" />
                    <TextField source="google.tid" label="Google TrackingID" />
                    <DateField source="createdAt" label="Angelegt am" notSearchable sortable/>
                </DataGrid>
            </List>
    )
};