import React from "react";

import SubmitButton from '../Form/SubmitButton';

export default props => {
    return (
        <div className="flex">
            {props?.record?.website && <a
                href={`https://${props?.record?.website}/`}
                className="domain"
                target="_blank"
                rel="noopener noreferrer"
            >
                {props?.record?.website} {props?.record?.lp && `Variante ${props.record.lp}`}
            </a>}
            {!props?.record?.website && props?.record?.createdFromBackend && <span className="domain">Erstellt durch Backend</span>}
            <SubmitButton {...props} />
        </div>
    );
}