import { useState, useEffect } from 'react';

export const useHasPropTech = (record) => {
  const [hasPropTech, setHasPropTech] = useState(false);

  useEffect(() => {
    const hpt = record && record.propTechDetails && record.propTechDetails.propTech ? true : false;
    setHasPropTech(hpt);
  }, [record])

  return hasPropTech;
}