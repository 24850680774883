import React, { useState } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRange } from 'react-date-range'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

export function dateRanges() {
  const endDate = new Date();
  const startDate = new Date();
  startDate.setMonth(startDate.getMonth() - 1);
  return { endDate, startDate };
}

export default props => {
  const { filterValues } = props;
  const [isVisible, setIsVisible] = useState(false);
  const [state, setState] = useState([
    {
      ...dateRanges(),
      key: 'selection'
    }
  ]);

  const handleClose = () => {
    setIsVisible(false);
    const [filter] = state;
    const createdAtFrom = new Date(filter.startDate).toISOString().slice(0, 10);
    const createdAtTo = new Date(filter.endDate).toISOString().slice(0, 10);
    props.setFilters({ ...props.filterValues, createdAtFrom, createdAtTo });
  }

  return (
    <>
      <Dialog
        open={isVisible}
        onClose={handleClose}
        onBackdropClick={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Datum setzen`}</DialogTitle>
        <DialogContent>
          <DateRange
            editableDateInputs={true}
            onChange={item => setState([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={state}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>Nein</Button>
          <Button onClick={handleClose} color="secondary">Ja</Button>
        </DialogActions>
      </Dialog>
      <Button color="primary" onClick={() => setIsVisible(true)}>{filterValues && filterValues.createdAtFrom && filterValues.createdAtTo ? `${new Date(filterValues.createdAtFrom).toLocaleDateString()}-${new Date(filterValues.createdAtTo).toLocaleDateString()}` : 'Datumbereich auswählen'}</Button>
    </>
  );
}