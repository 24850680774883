import React from "react";
import { Field } from 'react-final-form'
import StatefulInput from './StatefulInput';

function parseDate(input, format) {
    format = format || 'yyyy-mm-dd'; // default format
    if(!input || input.length === 0) return;
    var parts = input.match(/(\d+)/g),
        i = 0, fmt = {};
    if(!parts) return;
    // extract date-part indexes from the format
    format.replace(/(yyyy|dd|mm)/g, function (part) { fmt[part] = i++; });
    return new Date(parts[fmt['yyyy']], parts[fmt['mm']] - 1, parts[fmt['dd']]);
}


const dateParser = value => parseDate(value, 'dd.mm.yyyy');
const dateFormat = date => {
    if(!date) return;
    return date instanceof Date ? date.toLocaleDateString() : new Date(date).toLocaleDateString();
};

export default props => {
    const { isEditMode, source, label } = props;
    return (
        <Field name={source} parse={dateParser} format={dateFormat}>
            {({ input, meta }) => (
                <>
                    <p className="grey">{label}:</p>
                    <label className={"edit" + (isEditMode ? ' active' : '')}>
                        <StatefulInput input={input} />
                        {(meta.error || meta.submitError) && meta.touched && (
                            <span className="error">{meta.error || meta.submitError}</span>
                        )}
                    </label>
                </>
            )}
        </Field>
    );
}