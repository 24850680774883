import React from "react";

export default props => {
    const { saving, isEditMode } = props;
    return (
        <div className="padding">
            <input type="submit" className={'btn btn-blue btn-no-margin' + (saving || !isEditMode ? ' hide' : '')} value="Speichern" />
            <div className={'loader' + (saving ? ' show' : '')}>
                <img src="/img/loader.gif" alt="Ladebalken" title="Lädt" className={'loader' + (saving ? ' show' : '')} />
            </div>
        </div>
    );
}