import React from 'react';

import List from './List';
import DataGrid from './List/DataGrid';
import EnumReplaceField from './List/EnumReplaceField';
import TextField from './List/TextField';
import DateField from './List/DateField';
import StatusField from './List/StatusField';

import { objectTypes } from './LeadsList';
import MailField from './List/MailField';

const statusData = [
  {
    color: 'gray',
    label: 'offen',
    status: ['open'],
  },
  {
    color: 'yellow',
    label: 'Makler zugewiesen',
    status: ['processing'],
    processingSteps: ['agentAssigned'],
    notProcessingSteps: ['exclusiveContract'],
  },
  {
    color: 'pink',
    label: 'Exklusivvertrag',
    status: ['processing'],
    processingSteps: ['exclusiveContract'],
    notProcessingSteps: ['agentAssigned'],
  },
  {
    color: 'mixed',
    label: 'Exklusiv & Makler zugewiesen',
    status: ['processing'],
    processingSteps: ['agentAssigned', 'exclusiveContract'],
    and: true,
  },
  {
    color: 'green',
    label: 'verkauft & vergütet',
    status: ['closed'],
    processingSteps: ['sold'],
  },
];

export default (props) => {
  return (
    <List
      {...props}
      legendData={statusData}
      title="Immobiliensuche"
      className="sell"
      sort={{ field: 'createdAt', order: 'DESC' }}
      perPage={10}
    >
      <DataGrid rowClick="show">
        <StatusField source="listing.status" label="" notSearchable statusData={statusData} />
        <MailField source="customer.verified" label="E-Mail verifiziert" notSearchable />
        <DateField source="createdAt" label="Datum" showTime notSearchable sortable />
        <EnumReplaceField source="listing.objectType" label="Objekt" replace={objectTypes} />
        <TextField source="customer.lastName" label="Kontakt" />
        <TextField source="listing.city" label="Stadt" />
        <TextField source="listing.postalCode" label="PLZ" />
        <TextField source="website" label="Quelle" />
        <TextField source="agentDetails.name" label="Vermittelt an" />
        <TextField source="salesUser.name" label="Vertrieb" />
      </DataGrid>
    </List>
  );
};
