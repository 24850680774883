import React from "react";
import { useHistory } from 'react-router-dom';
import { useCreateController } from 'react-admin';

import CreateWebsite from './CreateView/Website';

export default props => {
    const history = useHistory();
    const createProps = useCreateController({ ...props, undoable: false, redirect: 'list' });
    return (
        <div className={'details'}>
            <div className="flex space">
                <h2 className="symbol">Webseite Anlegen</h2>
                <button onClick={history.goBack} className="btn btn-border"><i className="icon icon-chevron-left"></i>Zurück</button>
            </div>
            <CreateWebsite {...createProps} />
        </div>
        
    );
}