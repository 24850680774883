import React from 'react';

import DataGrid from '../List/DataGrid';
import TextField from '../List/TextField';
import List from '../List';
import SummaryFilter from './SummaryFilter';
import { dateRanges } from './DateRangeDialog';


function dep(filterValues, type) {
  if(filterValues == null || filterValues?.type == null) return false;
  return !filterValues.type.includes(type);
}

export default props => {
  const {startDate, endDate} = dateRanges();
  return (
    <List {...props} extraFilters={<SummaryFilter />} legendData={<SummaryFilter />} title="Zusammenfassung" hidePagination={true} filterDefaultValues={{createdAtFrom: startDate, createdAtTo: endDate}}>
      <DataGrid>
        <TextField label="" source="--id" notSearchable />
        <TextField label="Datum" source="id" notSearchable />
        <TextField label="Summe" source="counts.count" notSearchable defaultValue={0} />
        <TextField label="verkaufen" source="counts.saleCount" notSearchable dependentOnFilter={filterValues => dep(filterValues, "sale")} defaultValue={0} />
        <TextField label="bewerten" source="counts.ratingCount" notSearchable dependentOnFilter={filterValues => dep(filterValues, "rating")} defaultValue={0} />
        <TextField label="suche" source="counts.findingCount" notSearchable dependentOnFilter={filterValues => dep(filterValues, "finding")} defaultValue={0} />
        <TextField label="finanzieren" source="counts.financingCount" notSearchable dependentOnFilter={filterValues => dep(filterValues, "financing")} defaultValue={0} />
        <TextField label="kredit" source="counts.creditCount" notSearchable dependentOnFilter={filterValues => dep(filterValues, "Privatkredit")} defaultValue={0} />
      </DataGrid>
    </List>
  );
}