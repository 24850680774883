import React, { useState } from 'react';
import { useQuery } from 'ra-core';
import { Loading } from 'ra-ui-materialui';
import { leadTypes } from './LeadsList';

const DashboardOverviewItem = props => {
  return(
    <div className="overviewItem">
      <p>{props.website}</p>
      <div title="Heute" >{props.data.today}</div>
      <div title="Gestern">{props.data.yesterday}</div>
      <div title="letzte 7 Tage">{props.data.one_week}</div>
      <div title="letzte 14 Tage">{props.data.two_weeks}</div>
    </div>
  );
}

const DashboardOverviewType = props => {
  return(
    <div>
      <h4>{leadTypes[props.type]?.toUpperCase() ?? 'KREDITE'}</h4>
      {Object.keys(props.data).map((website) => {
        return <DashboardOverviewItem key={`${props.type}${website}`} website={website} data={props.data[website]} />
      })}
    </div> 
  );
}

export default props => {
  const [date] = useState(JSON.stringify({date: new Date(), offset: new Date().getTimezoneOffset()}));
  const {data, loading, error} = useQuery({
    type: 'getOne',

    resource: 'leads/dashboard/overview',
    payload: {id: date}
  });
  if(loading) return <Loading />
  if(error) return <p>ERROR</p>
  const dashboardData = data.overview;
  return(
    <div>
      {Object.keys(dashboardData).map((type, i) => {
        return <DashboardOverviewType key={`${type}`} type={type} data={dashboardData[type]} />
      })}
    </div>
  );
}

