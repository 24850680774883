import React from "react";
import get from 'lodash/get';

const formatHours = (value) => `${value.toString().padStart(2,'0')}:00`

export default ({ record, source, label }) => {
    let values = get(record, source);
    if(!values) return null;
    if(!Array.isArray(values)) {
        values = [
            values
        ]
    }
    return (
        <>
            {values.map(value => (
                <React.Fragment key={value.from+'-'+values.to} >
                    <p className="grey">{label}:</p>
                    <label className="edit">
                        {value.from && !value.to && (<span>Ab: </span>)}
                        {value.from && (<span>{formatHours(value.from)}</span>)}
                        {value.from && value.to && (<span> - </span>)}
                        {!value.from && value.to && (<span>Bis: </span>)}
                        {value.to && (<span>{formatHours(value.to)}</span>)}
                    </label>
                </React.Fragment>
            ))}
        </>    
    );
}