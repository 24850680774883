import React, { useCallback, useRef } from "react";
import { Field } from 'react-final-form'

export default props => {
    const { source, label } = props;
    const inputRef = useRef();
   
    return (
        <Field name={source}>
            {({ input, meta }) => {
                 const onAddCallback = useCallback((evt) => {
                    evt.preventDefault();
                    if(inputRef?.current?.value) {
                        input.onChange([ ...input.value, { city: (' ' + inputRef.current.value).slice(1)}]);
                        inputRef.current.value = "";
                    }
                }, [input]);
                return (
                    <>
                        <p className="grey">{label}:</p>
                        <label className="location">
                            <input type="text" ref={inputRef} />
                            <button className="btn-add" onClick={onAddCallback}>+</button>
                            {(meta.error || meta.submitError) && meta.touched && (
                                <span className="error">{meta.error || meta.submitError}</span>
                            )}
                        </label>
                    </>
                );
            }}
        </Field>
    );
}