import React from "react";
import { NavLink } from 'react-router-dom';
export default (props) => {
    return (
        <NavLink
            activeClassName="active"
            to={props.to}
            key={props.name}
            {...props}
        >
            {props.text}
        </NavLink>
    )
}