import React from "react";

import List from './List';
import DataGrid from './List/DataGrid';
import TextField from './List/TextField';
import StatusField from './List/StatusField';

import AgentsInlineEdit from './CreateView/AgentsInlineEdit';

const statusData = [
    {
        color: 'green',
        label: 'bezahlt',
        status: [
            'active'
        ]
    },
    {
        color: 'yellow',
        label: 'offen',
        status: [
            'active'
        ]
    },
    {
        color: 'red',
        label: 'gemahnt',
        status: [
            'active'
        ]
    },
]

const defaultSort = { field: 'createdAt', order: 'DESC' };

export default (props) => {
    return (
            <List {...props} legendData={statusData} sort={defaultSort} perPage={20}>
                <DataGrid rowClick="show" expand={AgentsInlineEdit} expandClass="edit-broker show" tableClassName="broker-table">
                    <StatusField source="status" label="Rechnungen" notSearchable statusData={statusData} />
                    <TextField source="postalCode" label="PLZ" />
                    <TextField source="city" label="Stadt" />
                    <TextField source="name" label="Name" showExapandButton />
                    <TextField source="leadsSuccess" label="verm. Leads" notSearchable />
                    <TextField source="leadsCanceld" label="abger. Leads" notSearchable />
                    <TextField source="sales" label="Umsatz" notSearchable />
                </DataGrid>
            </List>
    )
};


/*

 <tr class="edit-broker">
                    <td colspan="9">
                        <div class="flex row">
                            <div class="headline">
                                <h2>Makler-Details ansehen/bearbeiten</h2>
                                <hr class="line">
                            </div>
                            <div class="flex-item-33">
                                <label>
                                    Name des Maklers
                                    <input type="text">
                                </label>
                                <label>
                                    Stadt
                                    <input type="text">
                                </label>
                                <label>
                                    PLZ
                                    <input type="number">
                                    <span class="error">Bitte geben Sie eine gültige PLZ ein.</span>
                                </label>
                                <label>
                                    Straße, Hausnr.
                                    <input type="text">
                                </label>
                                <label class="location">
                                    Weitere Standorte
                                    <input type="text" placeholder="Standort hinzufügen">
                                    <input class="btn-add" type="submit" value="+">
                                </label>

                                <ul class="list-location">
                                    <li class="remove">Bückeburg <i class="icon icon-trash"></i></li>
                                    <li>Hildesheim <i class="icon icon-trash"></i></li>
                                </ul>
                            </div>
                            <div class="flex-item-33">
                                <label>
                                    E-Mail-Adresse
                                    <input type="email">
                                </label>
                                <label>
                                    Telefonnummer
                                    <input type="text">
                                    <span class="error">Bitte geben Sie eine gültige Telefonnummer ein.</span>
                                </label>
                                <label>
                                    Webseite
                                    <input type="text">
                                </label>
                                <label>
                                    Inhaber / Geschäftsführer
                                    <input type="text">
                                </label>
                                <label>
                                    Vertrieb durch
                                    <label class="custom-select">
                                        <select>
                                            <option disabled></option>
                                            <option>M. Bürst</option>
                                            <option>M. Dwornitschak</option>
                                            <option selected>C. Wiese</option>
                                        </select>
                                    </label>
                                </label>

                            </div>
                            <div class="flex-item-33">
                                <h3 class="personal-headline">Persönlicher Kontakt</h3>

                                <label>
                                    Name der Kontaktperson
                                    <input type="text">
                                </label>
                                <label>
                                    PLZ
                                    <input type="number">
                                </label>
                                <label>
                                    Stadt
                                    <input type="text">
                                </label>
                                <label>
                                    Straße, Hausnr.
                                    <input type="text">
                                </label>

                                <div>
                                    <p>Status des Maklers</p>
                                    <div class="radio">
                                        <input id="active" name="radio" type="radio" checked>
                                        <label for="active" class="radio-label">aktiv</label>
                                    </div>
                                    <div class="radio">
                                        <input id="inactive" name="radio" type="radio">
                                        <label for="inactive" class="radio-label">inaktiv</label>
                                    </div>
                                    <div class="radio">
                                        <input id="block" name="radio" type="radio">
                                        <label for="block" class="radio-label">sperren</label>
                                    </div>
                                    <label class="block">
                                        Grund für Sperrung
                                        <input type="text">
                                    </label>
                                </div>

                            </div>

                            <div class="interactions">
                                <button class="delete">Makler löschen</button>
                                <div class="loader">
                                    <img src="/img/loader.gif" alt="Ladebalken" title="Lädt" class="loader">
                                </div>
                                <input class="btn" type="submit" value="Änderungen übernehmen">
                            </div>
                        </div>
                    </td>
                </tr>

*/