import React from 'react';
import Select from 'react-select';
import { useListContext } from 'ra-core';

import DateRangeDialog from './DateRangeDialog';

const options = [
  { label: 'verkaufen', value: 'sale' },
  { label: 'bewerten', value: 'rating' },
  { label: 'finanzieren', value: 'financing' },
  { label: 'suche', value: 'finding' },
  { label: 'kredit', value: 'Privatkredit' },
]

export default props => {
  const context = useListContext(props);
  const { setFilters, filterValues } = context;
  const onChange = (values, action) => {
    const types = values && Array.isArray(values) ? values.map((type) => type.value).join(',') : '';
    setFilters({ ...filterValues, type: types });
  }

  return (
    <tr role="row">
    <th></th>
      <th class="sorting">
        <DateRangeDialog {...context} />
      </th>
      <th class="sorting" colSpan="100%">
        <Select
          defaultValue={[]}
          placeholder="Thema"
          isMulti
          name="types"
          options={options}
          className="basic-multi-select"
          classNamePrefix="select"
          onChange={onChange}
          closeMenuOnSelect={false}
        />
      </th>
    </tr>
  );
}