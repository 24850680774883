import React from 'react';

import List from './List';
import DataGrid from './List/DataGrid';
import EnumReplaceField from './List/EnumReplaceField';
import TextField from './List/TextField';
import DateField from './List/DateField';
import StatusField from './List/StatusField';

import { objectTypes } from './LeadsList';
import WebsiteTextField from './List/WebsiteTextField';
import MailField from './List/MailField';

const statusData = [
  {
    color: 'green',
    label: 'finanziert',
    status: ['closed'],
    processingSteps: ['financed', 'sold'],
  },
  {
    color: 'blue',
    label: 'vermittelt',
    status: ['processing'],
    processingSteps: ['conveyed'],
  },
  {
    color: 'gray',
    label: 'offen',
    status: ['open'],
  },
  {
    color: 'red',
    label: 'nicht gewünscht',
    status: ['noInterest', 'closed'],
    notProcessingSteps: ['sold', 'financed'],
  },
];

export default (props) => {
  return (
    <List
      {...props}
      legendData={statusData}
      title="Immobilienfinanzierung"
      className="finance"
      sort={{ field: 'createdAt', order: 'DESC' }}
      perPage={10}
    >
      <DataGrid rowClick="show">
        <StatusField source="listing.status" label="" notSearchable statusData={statusData} />
        <MailField source="customer.verified" label="E-Mail verifiziert" notSearchable />
        <DateField source="createdAt" label="Datum" showTime notSearchable sortable />
        <EnumReplaceField source="listing.objectType" label="Objekt" replace={objectTypes} />
        <TextField source="customer.lastName" label="Kontakt" />
        <TextField source="listing.city" label="Stadt" />
        <TextField source="listing.postalCode" label="PLZ" />
        <TextField source="listing.loanAmount" label="Darlehnssumme" isCurrency notSearchable sortable />
        <WebsiteTextField source="website" label="Quelle" showLP={true} />
        <TextField source="agentDetails.name" label="Finanzierer" />
      </DataGrid>
    </List>
  );
};
