import React from "react";
import get from 'lodash/get';

export default ({ record,source, statusData }) => {
    const status = get(record, source) ?? true;
    const isSent = !!(get(record, 'status.sentAt') ?? false);
    var sD = statusData.find(s => {
        if(!status) {
            return s.completed === status && s.sentAt === isSent;
        } else {
            return s.completed === status;
        }
    });
    
    return <span className={`status ${sD.color}`} title={sD.label + " - " + status}></span>
}