import React from 'react';
import dateFormat from '../../utils/dateFormat';

import List from '../List';
import DataGrid from '../List/DataGrid';
import DateField from '../List/DateField';
import StatusField from '../List/StatusField';
import TextField from '../List/TextField';

const defaultSort = { field: 'id', order: 'DESC' };

export default props => {
  return (
    <List {...props} legendData={[]} sort={defaultSort} perPage={10}>
      <DataGrid rowClick="show">
        <StatusField source="" label="" notSearchable statusData={[]} />
        <DateField source="id" label="Datum" notSearchable sortable dateFormat={dateFormat} />
        <TextField source="total" label="Verschickte Leads" />
      </DataGrid>
    </List>
  );
}