import React from "react";
import { Field } from 'react-final-form'

export default props => {
    const { source, label, additionalLabel = false } = props;
    return (
        <Field name={source}>
            {({ input, meta }) => (
                <>
                {additionalLabel && <p className="grey">{label}:</p>}
                    <textarea  {...input} className="textarea" placeholder={label} />
                    {(meta.error || meta.submitError) && meta.touched && (
                        <span className="error">{meta.error || meta.submitError}</span>
                    )}
                </>
            )}
        </Field>
    );
}