import React from 'react';
import get from 'lodash/get';
import BoolField from './BoolField';
import NumberInputField from './NumberInputField';

export default props => {
  const { source, label, record } = props;
  const value = get(record, source);
  return (
    <>
      <BoolField label={label} source={source} />
      {value ? <NumberInputField label={label.concat(' Höhe')} source={source.concat('Amount')} /> : null}
    </>
    );
}