import React from 'react';

export default (props) => {
    const { record, basePath, resource, field, handleToggleExpand } = props;
    return (
        <td>
            {React.cloneElement(field, {
                record,
                basePath: field.props.basePath || basePath,
                resource,
                handleToggleExpand
            })}
        </td>
    )
};