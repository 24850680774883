import React, { useCallback } from 'react';
import { ArrayField, Datagrid, useEditController } from 'react-admin';
import { useHistory } from 'react-router-dom';

import DetailForm from '../Form/DetailForm';
import OverviewFooter from '../EditView/OverviewFooter';
import TextInputField from '../Form/TextInputField';
import PatchPropTechSchema from './schema/PropTechEdit.json';
import DateField from '../List/DateField';
import TextField from '../List/TextField';

const EditPropTech = props => {
  const history = useHistory();

  const leadRowClick = d => {
    const values = JSON.parse(d);
    history.push(`/leads/${values.lead._id}/show`);
  }
  const forwardedLeadsCount = props.record && props.record.forwardedLeads ? props.record.forwardedLeads.length : 0;

  return (
    <div className="flex row">
      <div className="flex-item">
        <DetailForm
          formLabel="Übersicht"
          boxClassName="overview"
          validationSchema={PatchPropTechSchema}
          {...props}
        >
          <TextInputField source="name" label="Name" />
          <TextInputField source="email" label="E-Mail-Adresse" />

          <OverviewFooter />
        </DetailForm>
      </div>
      <div className="flex-item">
        <DetailForm formLabel={`Weitergeleitete Leads: ${forwardedLeadsCount}`}  boxClassName="forwardedLeads" {...props} noEditMode>
          <ArrayField source="forwardedLeads">
            <Datagrid rowClick={leadRowClick}>
              <DateField source="updatedAt" label="Datum" />
              <TextField source="lead.customer.firstName" label="Kontakt" />
              <TextField source="lead.customer.lastName" label="Kontakt" />
              <TextField source="lead.listing.city" label="Stadt" />
              <TextField source="lead.listing.postalCode" label="PLZ" />
            </Datagrid>
          </ArrayField>
        </DetailForm>
      </div>
    </div>
  );
}

export default props => {
  const history = useHistory();
  const editProps = useEditController({ ...props, undoable: false });
  const exportCallback = useCallback(() => {
    const record = editProps.record;
    if (!record) return;
    const json = JSON.stringify(record, null, 2);
    // eslint-disable-next-line no-undef
    const dlElement = document.createElement('a');
    dlElement.href = `data:application/json;charset=utf-8,${encodeURI(json)}`;
    dlElement.target = '_blank';
    dlElement.download = `lead-${record.id}.json`;
    dlElement.click();
  }, [editProps]);

  return (
    <div className={'details'}>
      <div className="flex space">
        <h2 className="symbol">PropTech</h2>
        <button onClick={history.goBack} className="btn btn-border"><i className="icon icon-chevron-left"></i>Zurück</button>
      </div>
      <EditPropTech {...editProps} />
      <button className="export" onClick={exportCallback}>Export</button>
      {/* {editProps.record && <DeleteButton {...editProps} />} */}
    </div>

  );
}