import * as React from 'react';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Notification, setSidebarVisibility } from 'react-admin';

import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import AppBar from './AppBar';
import SideBar from './SideBar';

const theme = createMuiTheme({});

const ImmoLeadLayout = ({
    children,
    dashboard,
    logout,
    title,
}) => {
    const dispatch = useDispatch();
    const open = useSelector(state => state.admin.ui.sidebarOpen);

    useEffect(() => {
        dispatch(setSidebarVisibility(true));
    }, [dispatch]);
    return (
        <ThemeProvider theme={theme}>
            <div>
                <AppBar title={title} open={open} logout={logout} />
                <div className="flex">
                    <SideBar hasDashboard={!!dashboard}>
                    </SideBar>
                    <div className={`content ${open ? '' : 'full'}`}>
                        {children}
                    </div>
                </div>
                <Notification />
            </div>
        </ThemeProvider>
    );
};

export default ImmoLeadLayout;