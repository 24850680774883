import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRefreshWhenVisible } from 'ra-core';
import { refreshView, useTranslate } from 'ra-core';

export default (props) => {
    useRefreshWhenVisible();
    const { onClick } = props;
    const loading = useSelector(state => state.admin.loading > 0);
    const dispatch = useDispatch();
    const translate = useTranslate();
    const handleClick = useCallback(
        event => {
            event.preventDefault();
            if (loading)
                return;
            dispatch(refreshView());
            if (typeof onClick === 'function') {
                onClick(event);
            }
        },
        [dispatch, onClick, loading]
    );
    return (
        <button className="link-button" onClick={handleClick}>
            <div className="flex flex-center">
                <i className={`icon icon-refresh-ccw ${loading ? 'loading' : ''}`}></i>
                <span>{translate('ra.action.refresh')}</span>
            </div>   
        </button>
    );
}