import React from "react";

export default ({ record, statusData }) => {
    const status = record?.status ?? 'default';
    let sD = {
        color: 'gray',
        label: 'default'
    };
    for (const d of statusData) {
        if (d.status && !d.status.includes(status))
            continue;
        sD = d;
        break;
    }
    return <span className={`status ${sD.color}`} title={sD.label + " - " + status}></span>
}