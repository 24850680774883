import React, { useCallback } from "react";
import { useHistory } from 'react-router-dom';
import { useEditController } from 'react-admin';
import get from 'lodash/get';


import DeleteButton from '../DeleteButton';
import DetailForm from "../Form/DetailForm";
import TextInputField from "../Form/TextInputField";
import ContactFooter from "./ContactFooter";
import SelectInputField from "../Form/SelectInputField";
import MailStatus from "../Form/MailStatus";
import DateInputField from "../Form/DateInputField";
import NumberInputField from "../Form/NumberInputField";
import ChildSupport from "../Form/ChildSupport";
import BoolField from "../Form/BoolField";

export const professionMainEarnerOptions = {
    'employee': 'Angestelle/r',
    'civil_servant': 'Beamte/r',
    'other': 'Sonstiges',
    'self_employed': 'Selbstständig',
    'retiree': 'Rentner/in',
    'privatier': 'Privatier',
    'student': 'Student/in'
}

export const genderTypes = {
    'male': 'Herr',
    'female': 'Frau',
    'diverse': 'Divers',
};

export const personalSituationTypes = {
    'single': 'alleinstehend',
    'married': 'verheiratet',
    'separated': 'getrennt lebend',
    'divorced': 'geschieden',
    'widowed': 'verwitwet',
    'cohabitation': 'eheähnliche Gemeinschaft',
}

export const livingSituationTypes = {
    rentalfee: 'Miete',
    house: 'Eigentum',
    rentfree: 'Mietfrei'
}

export const StepTypes = {
    1: "(1) Kontaktdetails",
    2: "(2) Vorpüfung",
    3: "(3) Persönliches",
    4: "(4) Berufliche Situation",
    5: "(5) Wohnsituation",
    6: "(6) Mitantragsteller",
    7: "(7) Bankverbindung",
    8: "(8) Zusammenfassung",
}

const T = props => {
    console.log('sendError', props?.record);
    const forwardedLead = get(props.record, "forwardedLead");
    const {forwardedBoy = {}, website = "", forwardedTo = []} = forwardedLead ?? {};
    const status = forwardedTo[forwardedTo.length - 1]?.success ?? "";
    return (
        <div className="flex row">
            <div className="flex-item">
                <DetailForm
                    formLabel="Kontaktdaten"
                    boxClassName="contact"
                    {...props}
                >
                    <SelectInputField source="applicant.gender" label="Geschlecht" options={genderTypes} />
                    <TextInputField source="applicant.firstname" label="Vorname" />
                    <TextInputField source="applicant.lastname" label="Nachname" />
                    <TextInputField source="applicant.email" label="E-Mail-Adresse" />
                    <MailStatus source="applicant.verified" label="E-Mail bestätigt" />
                    <TextInputField source="applicant.phone" label="Telefonnummer" />
                    <TextInputField source="applicant.placeOfBirth" label="Geburtsort" />
                    <TextInputField source="applicant.countryOfBirth" label="Geburtsland" />
                    <DateInputField source="applicant.birthday" label="Geburtstag" />
                    <SelectInputField source="applicant.personalSituation" label="Situation" options={personalSituationTypes} />
                    <NumberInputField source="applicant.householdAdults" label="Haushalt Erwachsene" />
                    <NumberInputField source="applicant.householdChildren" label="Haushalt Kinder" />
                    <ChildSupport label="Unterhaltspflicht" source="applicant.payAlimony" />
                    <ChildSupport label="Unterhalt erhalten" source="applicant.getAlimony" />
                    <SelectInputField label="Wohnsituation" source="applicant.livingSituation" options={livingSituationTypes} />
                    <BoolField label="Werbeeinwilligung" source="applicant.advertisementConsent" />
                    <BoolField label="Mitantragsteller" source="applicant.coApplicant" />
                    {props?.record?.coApplicant && <p class="bold">Angaben zweite Person:</p>}
                    {props?.record?.coApplicant && <SelectInputField source="coApplicant.gender" label="Geschlecht" options={genderTypes} /> }
                    {props?.record?.coApplicant && <TextInputField source="coApplicant.firstname" label="Vorname" /> }
                    {props?.record?.coApplicant && <TextInputField source="coApplicant.lastname" label="Nachname" /> }
                    {props?.record?.coApplicant && <TextInputField source="coApplicant.email" label="E-Mail-Adresse" /> }
                    {props?.record?.coApplicant && <MailStatus source="coApplicant.verified" label="E-Mail bestätigt" /> }
                    {props?.record?.coApplicant && <TextInputField source="coApplicant.phone" label="Telefonnummer" /> }
                    {props?.record?.coApplicant && <TextInputField source="coApplicant.placeOfBirth" label="Geburtsort" /> }
                    {props?.record?.coApplicant && <TextInputField source="coApplicant.countryOfBirth" label="Geburtsland" /> }
                    {props?.record?.coApplicant && <DateInputField source="coApplicant.birthday" label="Geburtstag" /> }
                    {props?.record?.coApplicant && <SelectInputField source="coApplicant.personalSituation" label="Situation" options={personalSituationTypes} /> }
                    {props?.record?.coApplicant && <NumberInputField source="coApplicant.householdAdults" label="Haushalt Erwachsene" /> }
                    {props?.record?.coApplicant && <NumberInputField source="coApplicant.householdChildren" label="Haushalt Kinder" /> }
                    {props?.record?.coApplicant && <ChildSupport label="Unterhaltspflicht" source="coApplicant.payAlimony" /> }
                    {props?.record?.coApplicant && <ChildSupport label="Unterhalt erhalten" source="coApplicant.getAlimony" /> }
                    <ContactFooter />
                </DetailForm>
            </div>
            <div className="flex-item">
                <DetailForm
                    formLabel="Kredit Informationen"
                    boxClassName="overview"
                    {...props}
                >
                <TextInputField source="type" label="Kredit Typ" />
                <NumberInputField source="credit.amount" label="Kreditsumme" currency />
                <NumberInputField source="credit.monthlyRate" label="Monatliche Rate" currency />
                <NumberInputField source="credit.runtime" label="Kredit Laufzeit (Monate)" />
                {props?.record?.status?.lastStep && <BoolField source="status.completed" label="Lead komplett ausgefüllt" />}
                {props?.record?.status?.lastStep && <SelectInputField source="status.lastStep" label="Letzter Step" options={StepTypes} />}
                {props?.record?.status?.lastStep && <TextInputField source="status.stepSubmittedAt" label="Step timestamp" />}
                {props?.record?.status?.lastStep && <TextInputField source="status.sentAt" label="Lead übertragen am" />}
                </DetailForm>
                <DetailForm
                    formLabel="Opt-in Mail Status"
                    boxClassName="overview"
                    {...props}
                >
                    <BoolField source="applicant.verified.isDelivered" label="Mail ausgeliefert" />
                    {props?.record?.applicant?.verified?.isDelivered && <TextInputField source="applicant.verified.deliveredAt" label="Ausgeliefert Am" />}
                    {props?.record?.applicant?.verified?.sendError && <TextInputField source="applicant.verified.sendError" label="Error Typ" />}

                    {props?.record?.applicant?.verified?.delivery && <p class="bold">Delivery Details:</p>}
                    {props?.record?.applicant?.verified?.delivery && <TextInputField source="applicant.verified.delivery.timestamp" label="timestamp" />}
                    {props?.record?.applicant?.verified?.delivery && <TextInputField source="applicant.verified.delivery.processingTimeMillis" label="processingTimeMillis" />}
                    {props?.record?.applicant?.verified?.delivery && <TextInputField source="applicant.verified.delivery.reportingMTA" label="reportingMTA" />}
                    {props?.record?.applicant?.verified?.delivery && <TextInputField source="applicant.verified.delivery.smtpResponse" label="smtpResponse" />}

                    {props?.record?.applicant?.verified?.deliveryDelay && <p class="bold">DeliveryDelay Details:</p>}
                    {props?.record?.applicant?.verified?.deliveryDelay && <TextInputField source="applicant.verified.deliveryDelay.deliveryDelayType" label="deliveryDelayType" />}
                    {props?.record?.applicant?.verified?.deliveryDelay && <TextInputField source="applicant.verified.deliveryDelay.expirationTime" label="expirationTime" />}
                    {props?.record?.applicant?.verified?.deliveryDelay && <TextInputField source="applicant.verified.deliveryDelay.reportingMTA" label="reportingMTA" />}
                    {props?.record?.applicant?.verified?.deliveryDelay && <TextInputField source="applicant.verified.deliveryDelay.status" label="status" />}
                    {props?.record?.applicant?.verified?.deliveryDelay && <TextInputField source="applicant.verified.deliveryDelay.diagnosticCode" label="diagnosticCode" />}

                    {props?.record?.applicant?.verified?.bounce && <p class="bold">Bounce Details:</p>}
                    {props?.record?.applicant?.verified?.bounce && <TextInputField source="applicant.verified.bounce.bounceType" label="bounceType" />}
                    {props?.record?.applicant?.verified?.bounce && <TextInputField source="applicant.verified.bounce.subType" label="subType" />}
                    {props?.record?.applicant?.verified?.bounce && <TextInputField source="applicant.verified.bounce.timestamp" label="timestamp" />}
                    {props?.record?.applicant?.verified?.bounce && <TextInputField source="applicant.verified.bounce.feedbackId" label="feedbackId" />}
                    {props?.record?.applicant?.verified?.bounce && <TextInputField source="applicant.verified.bounce.reportingMTA" label="reportingMTA" />}
                    {props?.record?.applicant?.verified?.bounce && <TextInputField source="applicant.verified.bounce.action" label="action" />}
                    {props?.record?.applicant?.verified?.bounce && <TextInputField source="applicant.verified.bounce.status" label="status" />}
                    {props?.record?.applicant?.verified?.bounce && <TextInputField source="applicant.verified.bounce.diagnosticCode" label="diagnosticCode" />}

                    {props?.record?.applicant?.verified?.complaint && <p class="bold">Complaint Details:</p>}
                    {props?.record?.applicant?.verified?.complaint && <TextInputField source="applicant.verified.complaint.complaintType" label="complaintType" />}
                    {props?.record?.applicant?.verified?.complaint && <TextInputField source="applicant.verified.complaint.feedbackId" label="feedbackId" />}
                    {props?.record?.applicant?.verified?.complaint && <TextInputField source="applicant.verified.complaint.userAgent" label="userAgent" />}
                    {props?.record?.applicant?.verified?.complaint && <TextInputField source="applicant.verified.complaint.complaintFeedbackType" label="complaintFeedbackType" />}
                </DetailForm>
                <DetailForm formLabel="Weitergeleiteter Lead" boxClassName="overview" {...props} noEditMode={false}>
                <pre>
                    {JSON.stringify({status, website, forwardedBody: forwardedBoy}, null, 2)}
                </pre>
                </DetailForm>
            </div>
            
        </div>
    );
}

export default props => {
    const history = useHistory();
    const editProps = useEditController({ ...props, undoable: false });
    const exportCallback = useCallback(() => {
        const record = editProps.record;
        if(!record) return;
        const json = JSON.stringify(record, null, 2);
        // eslint-disable-next-line no-undef
        const dlElement = document.createElement('a');
        dlElement.href = `data:application/json;charset=utf-8,${encodeURI(json)}`;
        dlElement.target = '_blank';
        dlElement.download = `lead-${record.id}.json`;
        dlElement.click();
    }, [editProps]);

    return (
        <div className={'details'}>
            <div className="flex space">
                <h2 className="symbol">Lead</h2>
                <button onClick={history.goBack} className="btn btn-border"><i className="icon icon-chevron-left"></i>Zurück</button>
            </div>
            <T {...editProps} />
            <button className="export" onClick={exportCallback}>Export</button>
            {editProps.record && <DeleteButton {...editProps}/>}
        </div>
        
    );
}