import React from "react";

import List from './List';
import DataGrid from './List/DataGrid';
import EnumReplaceField from './List/EnumReplaceField';
import TextField from './List/TextField';
import DateField from './List/DateField';
import StatusPKField from "./List/StatusPKField";

export const creditLeadTypes = {
  "Anschaffung": "Anschaffung",
  "Autokauf": "Autokauf",
  "freie Verfügung": "freie",
  "Dispoausgleich": "Dispoausgleich",
  "Modernisierung": "Modernisierung",
  "Kreditzusammenfassung": "Kreditzusammenfassung",
  "Ratenreduzierung": "Ratenreduzierung",
  "Urlaub": "Urlaub",
  "Schuldentilgung": "Schuldentilgung",
  "Bargeld": "Bargeld",
  "Sonstiges": "Sonstiges",
  "Konsumentenkredit": "Konsumentenkredit",
  "Baufinanzierung": "Baufinanzierung",
  "Immobilienfinanzierung": "Immobilienfinanzierung",
  "Umschuldung": "Umschuldung",
  "Autokredit": "Autokredit",
  "Kautionskredit": "Kautionskredit",
  "Privatkredit": "Privatkredit",
  "Uhrenkredit": "Uhrenkredit",
};

const statusData = [
  {
    color: 'green',
    label: 'Lead vollständig',
    completed: true,
  },
  {
    color: 'yellow',
    label: 'Wird ausgefüllt',
    completed: false,
    sentAt: false,
  },
  {
    color: 'blue',
    label: 'Unvollständig übermittelt',
    completed: false,
    sentAt: true,
  },
];

const defaultSort = { field: 'createdAt', order: 'DESC' };

export default (props) => {
  return (
    <List {...props} legendData={statusData} sort={defaultSort} perPage={10}>
      <DataGrid rowClick="show">
        <StatusPKField source="status.completed" label="" notSearchable statusData={statusData} />
        <DateField source="createdAt" label="Datum" showTime notSearchable sortable />
        <EnumReplaceField source="type" label="Thema" replace={creditLeadTypes} />
        <TextField source="applicant.lastname" label="Kontakt" />
        <TextField source="applicant.location" label="Stadt" />
        <TextField source="applicant.postalcode" label="PLZ" />
        <TextField source="caseToken" label="Case Token" />
        <TextField source="status.lastStep" label="Step" />
        <TextField source="website" label="Quelle" />
      </DataGrid>
    </List>
  )
};