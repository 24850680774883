import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import List from './List';
import DataGrid from './List/DataGrid';
import EnumReplaceField from './List/EnumReplaceField';
import TextField from './List/TextField';
import DateField from './List/DateField';
import StatusField from './List/StatusField';
import WebsiteTextField from './List/WebsiteTextField';
import MailField from './List/MailField';

export const objectTypes = {
  flat: 'Wohnung',
  house: 'Einfamilienhaus',
  apartment_building: 'Mehrfamilienhaus',
  commercial_space: 'Gewerbefläche',
  commercial_building: 'Gewerbeobjekt',
  property: 'Grundstück',
  new_construction: 'Neubau',
  buy_real_estate: 'Kauf einer Immobilie',
  unknown: 'Unbekannt',
};
export const leadTypes = {
  sale: 'verkaufen',
  rating: 'bewerten',
  financing: 'finanzieren',
  finding: 'suche',
};

/*

Sale Lead:

open -> processing -> closed
  |-> inactive, noInterest

processingSteps:
  agentAssigned     -> agent pushed
  exclusiveContract  -> exclusiveContract uploaded
  sold  -> bill uploaded

Rating Lead:

open -> processing -> closed
  |-> inactive, noInterest

processingSteps:
  recommended  -> checkbox
  sold -> bill uploaded

Finance Lead:

open -> processing -> closed
  |-> inactive, noInterest

processingSteps:
  conveyed -> checkbox
  financed -> bill uploaded

*/

const statusData = [
  {
    color: 'gray',
    label: 'offen',
    status: ['open'],
  },
  {
    color: 'yellow',
    label: 'in Bearbeitung',
    status: ['processing'],
  },
  {
    color: 'red',
    label: 'nicht verwertbar',
    status: ['inactive', 'noInterest', 'closed'],
    notProcessingSteps: ['sold', 'financed'],
  },
  {
    color: 'blue',
    label: 'vermittelt',
    status: ['processing'],
    processingSteps: ['conveyed', 'recommended'],
  },
  {
    color: 'green',
    label: 'erfolgreich',
    status: ['closed'],
    processingSteps: ['sold', 'financed'],
  },
];

const defaultSort = { field: 'createdAt', order: 'DESC' };

export default (props) => {
  const history = useHistory();
  const addNewCallback = useCallback(() => {
    if (!history) return;
    history.push(`leads/create`);
  }, [history]);

  return (
    <List {...props} legendData={statusData} handleAdd={addNewCallback} sort={defaultSort} perPage={10}>
      <DataGrid rowClick="show">
        <StatusField source="listing.status" label="" notSearchable statusData={statusData} />
        <MailField source="customer.verified" label="E-Mail verifiziert" notSearchable />
        <DateField source="createdAt" label="Datum" showTime notSearchable sortable />
        <EnumReplaceField source="listing.objectType" label="Objekt" replace={objectTypes} />
        <EnumReplaceField source="type" label="Thema" replace={leadTypes} />
        <TextField source="customer.lastName" label="Kontakt" />
        <TextField source="listing.city" label="Stadt" />
        <TextField source="listing.postalCode" label="PLZ" />
        {/* <TextField source="website" label="Quelle" /> */}
        <WebsiteTextField source="website" label="Quelle" showLP={true} />
        <TextField source="agentDetails.name" label="Vermittelt an" />
        <TextField source="utm.campaign" label="UTM" notSearchable />
      </DataGrid>
    </List>
  );
};
