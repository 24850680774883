import React from "react";
import { useEditController } from 'react-admin';
import ImmoForm from '../Form/ImmoForm';
import TextInputField from '../Form/TextInputField';
import AgentStatusRadioInputField from '../Form/AgentStatusRadioInputField';
import LocationTextInputField from '../Form/LocationTextInputField';
import LocationList from '../Form/LocationList';
import Condition from '../Form/Condition';
import SubmitButton from '../Form/SubmitButton';

const statusOptions = {
    active : 'aktiv',
    inactive: 'inaktiv',
    locked: 'sperren'
};

/*
    TODO:
    Eye-Button style
    Form style
    PLZ zuordnen wenn neue Location gespeichert wird
    Delete-Button funktion
*/

export default (props) => {
    const editProps = useEditController({ ...props, undoable: false });
    return (
        <td colSpan="9">
            <ImmoForm
            {...editProps}
            //validationSchema={}
            >
                {(formProps) => {
                    return (
                        <div className="flex row">
                            <div className="headline">
                                <h2>Makler-Details ansehen/bearbeiten</h2>
                                <hr className="line" />
                            </div>
                            <div className="flex-item-33">
                                <TextInputField source="name" label="Name des Maklers" {...formProps} />
                                <TextInputField source="postalCode" label="PLZ" {...formProps} />
                                <TextInputField source="city" label="Stadt" {...formProps} />
                                <TextInputField source="addressLine" label="Straße, Hausnr." {...formProps} />
                                <LocationTextInputField source="locations" label="Weitere Standorte" {...formProps} />
                                <LocationList source="locations" {...formProps} />
                            </div>
                            <div className="flex-item-33">
                                <TextInputField source="email" label="E-Mail-Adresse" {...formProps} />
                                <TextInputField source="phone" label="Telefonnummer" {...formProps} />
                                <TextInputField source="website" label="Webseite" {...formProps} />
                                <TextInputField source="owner" label="Inhaber / Geschäftsführer" {...formProps} />
                            </div>
                            <div className="flex-item-33">
                                <h3 className="personal-headline">Persönlicher Kontakt</h3>
                                <TextInputField source="contact.name" label="Name der Kontaktperson" {...formProps} />
                                <TextInputField source="contact.postalCode" label="PLZ" {...formProps} />
                                <TextInputField source="contact.city" label="Stadt" {...formProps} />
                                <TextInputField source="contact.addressLine" label="Straße, Hausnr." {...formProps} />
                                <AgentStatusRadioInputField source="status" label="Status des Maklers" options={statusOptions} {...formProps} />
                                <Condition when="status" is="locked">
                                    <TextInputField source="lockedReason" label="Grund für Sperrung" {...formProps} />
                                </Condition>
                            </div>
                            <div className="interactions">
                                <button className="delete">Löschen</button>
                                <SubmitButton buttonClassName="btn" {...formProps}/>
                            </div>
                        </div>
                    );
                }}
            </ImmoForm>
        </td>
    )
}

/*

 <tr class="edit-broker">
                    <td colspan="9">
                        <div class="flex row">
                            <div class="headline">
                                <h2>Makler-Details ansehen/bearbeiten</h2>
                                <hr class="line">
                            </div>
                            <div class="flex-item-33">
                                <label>
                                    Name des Maklers
                                    <input type="text">
                                </label>
                                <label>
                                    Stadt
                                    <input type="text">
                                </label>
                                <label>
                                    PLZ
                                    <input type="number">
                                    <span class="error">Bitte geben Sie eine gültige PLZ ein.</span>
                                </label>
                                <label>
                                    Straße, Hausnr.
                                    <input type="text">
                                </label>
                                <label class="location">
                                    Weitere Standorte
                                    <input type="text" placeholder="Standort hinzufügen">
                                    <input class="btn-add" type="submit" value="+">
                                </label>

                                <ul class="list-location">
                                    <li class="remove">Bückeburg <i class="icon icon-trash"></i></li>
                                    <li>Hildesheim <i class="icon icon-trash"></i></li>
                                </ul>
                            </div>
                            <div class="flex-item-33">
                                <label>
                                    E-Mail-Adresse
                                    <input type="email">
                                </label>
                                <label>
                                    Telefonnummer
                                    <input type="text">
                                    <span class="error">Bitte geben Sie eine gültige Telefonnummer ein.</span>
                                </label>
                                <label>
                                    Webseite
                                    <input type="text">
                                </label>
                                <label>
                                    Inhaber / Geschäftsführer
                                    <input type="text">
                                </label>
                                <label>
                                    Vertrieb durch
                                    <label class="custom-select">
                                        <select>
                                            <option disabled></option>
                                            <option>M. Bürst</option>
                                            <option>M. Dwornitschak</option>
                                            <option selected>C. Wiese</option>
                                        </select>
                                    </label>
                                </label>

                            </div>
                            <div class="flex-item-33">
                                <h3 class="personal-headline">Persönlicher Kontakt</h3>

                                <label>
                                    Name der Kontaktperson
                                    <input type="text">
                                </label>
                                <label>
                                    PLZ
                                    <input type="number">
                                </label>
                                <label>
                                    Stadt
                                    <input type="text">
                                </label>
                                <label>
                                    Straße, Hausnr.
                                    <input type="text">
                                </label>

                                <div>
                                    <p>Status des Maklers</p>
                                    <div class="radio">
                                        <input id="active" name="radio" type="radio" checked>
                                        <label for="active" class="radio-label">aktiv</label>
                                    </div>
                                    <div class="radio">
                                        <input id="inactive" name="radio" type="radio">
                                        <label for="inactive" class="radio-label">inaktiv</label>
                                    </div>
                                    <div class="radio">
                                        <input id="block" name="radio" type="radio">
                                        <label for="block" class="radio-label">sperren</label>
                                    </div>
                                    <label class="block">
                                        Grund für Sperrung
                                        <input type="text">
                                    </label>
                                </div>

                            </div>

                            <div class="interactions">
                                <button class="delete">Makler löschen</button>
                                <div class="loader">
                                    <img src="/img/loader.gif" alt="Ladebalken" title="Lädt" class="loader">
                                </div>
                                <input class="btn" type="submit" value="Änderungen übernehmen">
                            </div>
                        </div>
                    </td>
                </tr>

*/