import React from "react";
import { Field } from 'react-final-form'
import StatefulInput from './StatefulInput';

const floatParser = value => {
    if(!value) return;
    let val = value.replace(/\./g, '');
    val = val.replace(/,/g, '.');
    return (isNaN(parseFloat(val)) ? "" : parseFloat(val));
};
const intParser = value => {
    if(!value) return;
    let val = value.replace(/\./g, '');
    return (isNaN(parseInt(val)) ? "" : parseInt(val));
};
const numberFormat = new Intl.NumberFormat('de-DE');
const currencyFormat = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' });
const formatValue = value => isNaN(value) ? '' : numberFormat.format(value);
const formatCurrency = value => isNaN(value) ? '' : currencyFormat.format(value);
export default props => {
    const { isEditMode, source, label, float, currency } = props;
    return (
        <Field name={source} parse={float || currency ? floatParser : intParser} format={isEditMode || !currency ? formatValue : formatCurrency}>
            {({ input, meta }) => (
                <>
                    <p className="grey">{label}:</p>
                    <label className={"edit" + (isEditMode ? ' active' : '')}>
                        <StatefulInput input={input} />
                        {(meta.error || meta.submitError) && meta.touched && (
                            <span className="error">{meta.error || meta.submitError}</span>
                        )}
                    </label>
                </>
            )}
        </Field>
    );
}