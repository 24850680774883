import React from "react";

import DetailForm from '../Form/DetailForm';
import TextInputField from '../Form/TextInputField';
import TextAreaInputField from '../Form/TextAreaInputField';
import NumberInputField from '../Form/NumberInputField';
import DateInputField from '../Form/DateInputField';
import PostalCodeInputField from '../Form/PostalCodeInputField';
import SelectInputField from '../Form/SelectInputField';
import StatusRadioInputField from '../Form/StatusRadioInputField';
import MailStatus from '../Form/MailStatus';
import Reachability from '../Form/Reachability';
import AgentHistoryOverlay from './AgentHistoryOverlay';

import ContactFooter from './ContactFooter';
import OverviewFooter from './OverviewFooter';
import SubmitButton from '../Form/SubmitButton';

import EstimationView from '../Form/EstimationView';

import { objectTypes } from '../LeadsList';
import { financeTimeFrameOptions, objectStatusOptions, professionMainEarnerOptions, statusOptions, usageOptions, contactFormOptions } from '../LeadsEdit';


import listingSchema from './schema/LeadFinancingListing.json';
import customerSchema from './schema/LeadFinancingCustomer.json';
import remarksSchema from './schema/LeadRemarks.json';
import AdvisorContactStatus from "../Form/AdvisorContactStatus";
export default props => {

    return (
        <div className="flex row">
            <div className="flex-item">
                <DetailForm
                    formLabel="Übersicht"
                    boxClassName="overview"
                    validationSchema={listingSchema}
                    {...props}
                >
                    <SelectInputField source="listing.objectType" label="Objekt" options={objectTypes} />
                    <NumberInputField source="listing.loanAmount" label="Darlehenssummme" currency />
                    <NumberInputField source="listing.listedValue" label="Kaufpreis" currency />
                    <NumberInputField source="listing.equity" label="Eigenkapital" currency />
                    <PostalCodeInputField source="listing.postalCode" label="Postleitzahl" />
                    <SelectInputField source="listing.usage" label="Nutzung" options={usageOptions} />
                    <SelectInputField source="listing.objectStatus" label="Status Immobiliensuche" options={objectStatusOptions} />
                    <SelectInputField source="listing.financeTimeFrame" label="Wann Finanzierung" options={financeTimeFrameOptions} />
                    <StatusRadioInputField source="listing.status" label="Status der Finanzierung" options={statusOptions} />
                    <OverviewFooter />
                </DetailForm>
                <DetailForm
                    formLabel="Kontaktdaten"
                    boxClassName="contact"
                    validationSchema={customerSchema}
                    {...props}
                >
                    <TextInputField source="customer.firstName" label="Vorname" />
                    <TextInputField source="customer.lastName" label="Nachname" />
                    {props?.record?.customer.address && <TextInputField source="customer.address.street" label="Straße" /> }
                    {props?.record?.customer.address && <TextInputField source="customer.address.number" label="Hausnummer" /> }
                    {props?.record?.customer.address && <TextInputField source="customer.address.postalCode" label="Postleitzahl" /> }
                    {props?.record?.customer.address && <TextInputField source="customer.address.city" label="Ort" /> }
                    <TextInputField source="customer.email" label="E-Mail-Adresse" />
                    <MailStatus source="customer.verified" label="E-Mail bestätigt" />
                    <TextInputField source="customer.phone" label="Telefonnummer" />
                    <TextInputField source="customer.additionalPhone" label="Weitere Telefonnummer" />
                    {props?.record?.customer?.contactForm && <SelectInputField source="customer.contactForm" label="Wünscht Angebotserstellung über" options={contactFormOptions} />}
                    <AdvisorContactStatus source="listing.advisorMayContact" label="Wünscht telefonische Beratung" />
                    <Reachability source="customer.reachability" label="Erreichbarkeit" />
                    {props?.record?.customer?.birthYear && <TextInputField source="customer.birthYear" label="Geburtsjahr" />}
                    {!props?.record?.customer?.birthYear && <DateInputField source="customer.birthDate" label="Geburtsdatum" />}
                    <NumberInputField source="customer.income" label="montl. Nettoeinkommen" currency />
                    <NumberInputField source="listing.monthlyHouseholdIncome" label="Haushaltsnetto mtl." currency />
                    <SelectInputField source="listing.professionMainEarner" label="Beruf Hauptverdiener" options={professionMainEarnerOptions} />

                    {props?.record?.customer2 && <p class="bold">Angaben zweite Person:</p>}
                    {props?.record?.customer2 && <TextInputField source="customer2.firstName" label="Vorname" />}
                    {props?.record?.customer2 && <TextInputField source="customer2.lastName" label="Nachname" />}
                    {props?.record?.customer2 && <TextInputField source="customer2.email" label="E-Mail-Adresse" />}
                    {props?.record?.customer2 && <TextInputField source="customer2.phone" label="Telefonnummer" />}
                    {props?.record?.customer2 && <Reachability source="customer2.reachability" label="Erreichbarkeit" />}
                    {props?.record?.customer2 && <DateInputField source="customer2.birthDate" label="Geburtsdatum" />}
                    {props?.record?.customer2 && <NumberInputField source="customer2.income" label="montl. Nettoeinkommen" currency />}

                    <ContactFooter />
                </DetailForm>
            </div>
            <div className="flex-item">
                <DetailForm
                    formLabel="Makler zuweisen"
                    boxClassName="assign"
                    noEditMode
                    overlay={<AgentHistoryOverlay />}
                    {...props}
                >
                    <SelectInputField source="agentDetails.agent" label="Vertriebsmitarbeiter" options={{}} />
                    <SelectInputField source="salesUser" label="Stadt" options={{}} />
                </DetailForm>
                <DetailForm
                    formLabel="Ermittelter Durchschnittswert"
                    boxClassName=""
                    noEditMode
                    {...props}
                >
                   <EstimationView />
                </DetailForm>
                <DetailForm
                    formLabel="Sonstige Anmerkungen"
                    boxClassName=""
                    noEditMode
                    validationSchema={remarksSchema}
                    {...props}
                >
                    <TextAreaInputField source="remarks" label="Notizen" />
                    <SubmitButton />
                </DetailForm>
            </div>    
        </div>
    );
}
