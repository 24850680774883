import React, { useState } from "react";

import ImmoForm from '../Form/ImmoForm';
import TextInputField from '../Form/TextInputField';
import NumberInputField from '../Form/NumberInputField';
import SelectInputField from '../Form/SelectInputField';
import PostalCodeInputField from '../Form/PostalCodeInputField';
import DateInputField from '../Form/DateInputField';

import SubmitButtonLeft from '../Form/SubmitButtonLeft';

import leadCreateSchema from './schema/LeadCreate.json';

import { objectTypes, leadTypes } from '../LeadsList';

export default props => {

    const [type, setType] = useState('sale');

    return (
        <ImmoForm
            {...props}
            validationSchema={leadCreateSchema}
        >
            {(formProps) => {
                return (
                    <div className="flex row">
                        <div className="flex-item">
                            <div className={"shadow-box overview"}>
                                <h3 className="underlined">Übersicht</h3>
                                <div className="details-info">
                                    <SelectInputField source="type" label="Typ" options={leadTypes} {...formProps} changeHandler={setType}/>
                                    <SelectInputField source="listing.objectType" label="Objekt" options={objectTypes} {...formProps} />
                                    <PostalCodeInputField source="listing.postalCode" label="Postleitzahl" {...formProps} />
                                    <TextInputField source="listing.street" label="Straße" {...formProps} />
                                    <NumberInputField source="listing.rooms" label="Zimmeranzahl" {...formProps} />
                                    <NumberInputField source="listing.area" label="Größe in m&sup2;" {...formProps} />
                                    {type === 'sale' && <NumberInputField source="listing.listedValue" label="Preisvorstellung" currency {...formProps} />}
                                    {type === 'sale' && <NumberInputField source="listing.estimatedValue" label="Preiseinschätzung" currency {...formProps} />}
                                    {type === 'financing' && <NumberInputField source="listing.listedValue" label="Kaufpreis" currency {...formProps} />}
                                    {type === 'financing' && <NumberInputField source="listing.loanAmount" label="Darlehenssumme" currency {...formProps} />}
                                </div>
                            </div>
                        </div>
                        <div className="flex-item">
                            <div className={"shadow-box contact"}>
                                <h3 className="underlined">Kontaktdaten</h3>
                                <div className="details-info">
                                    <TextInputField source="customer.firstName" label="Vorname" {...formProps} />
                                    <TextInputField source="customer.lastName" label="Nachname" {...formProps} />
                                    <TextInputField source="customer.email" label="E-Mail-Adresse" {...formProps} />
                                    <TextInputField source="customer.phone" label="Telefonnummer" {...formProps} />
                                    <TextInputField source="customer.additionalPhone" label="Weitere Telefonnummer" {...formProps} />
                                    {type === 'financing' && <DateInputField source="customer.birthDate" label="Geburtsdatum" {...formProps} />}
                                    {type === 'financing' && <NumberInputField source="customer.income" label="montl. Nettoeinkommen" currency {...formProps} />}

                                    {type === 'financing' && <p class="bold">Angaben zweite Person</p>}
                                    {type === 'financing' && <TextInputField source="customer2.firstName" label="Vorname" {...formProps} />}
                                    {type === 'financing' && <TextInputField source="customer2.lastName" label="Nachname" {...formProps} />}
                                    {type === 'financing' && <TextInputField source="customer2.email" label="E-Mail-Adresse" {...formProps} />}
                                    {type === 'financing' && <TextInputField source="customer2.phone" label="Telefonnummer" {...formProps} />}
                                    {type === 'financing' && <DateInputField source="customer2.birthDate" label="Geburtsdatum" {...formProps} />}
                                    {type === 'financing' && <NumberInputField source="customer2.income" label="montl. Nettoeinkommen" currency {...formProps} />}
                                </div>
                            </div>
                        </div>
                        <div className="flex-item">
                            <SubmitButtonLeft {...formProps} />
                        </div>
                    </div>
                );
            }}
        </ImmoForm>
    );
}

/*
sale:

Preisvorstellung

rating:

Straße

financing:

Darlehenssumme
Kaufpreis
Geburtsdatum
Nettoeinkommen

sale + rating (!financing):

m2
Zimmer

Alle:

Postleitzahl
Vorname
Nachname
Mail
Telefonnummer

 */