import React from "react";

import ImmoForm from '../Form/ImmoForm';
import TextInputField from '../Form/TextInputField';

import SubmitButtonLeft from '../Form/SubmitButtonLeft';

import websiteCreateSchema from './schema/WebsiteCreate.json';

export default props => {

    return (
        <ImmoForm
            {...props}
            validationSchema={websiteCreateSchema}
        >
            {(formProps) => {
                return (
                    <div className="flex row">
                        <div className="flex-item">
                            <div className={"shadow-box overview"}>
                                <h3 className="underlined">Allgemein</h3>
                                <div className="details-info">
                                   <TextInputField source="domain" label="Domain" {...formProps} />
                                </div>
                            </div>
                        </div>
                        <div className="flex-item">
                            <div className={"shadow-box overview"}>
                                <h3 className="underlined">Tracking</h3>
                                <div className="details-info">
                                   <TextInputField source="google.tid" label="Google TrackingID" {...formProps} />
                                </div>
                            </div>
                        </div>
                        <div className="flex-item">
                            <SubmitButtonLeft {...formProps} />
                        </div>
                    </div>
                );
            }}
        </ImmoForm>
    );
}

/*
sale:

Preisvorstellung

rating:

Straße

financing:

Darlehenssumme
Kaufpreis
Geburtsdatum
Nettoeinkommen

sale + rating (!financing):

m2
Zimmer

Alle:

Postleitzahl
Vorname
Nachname
Mail
Telefonnummer

 */