import React, { useCallback } from "react";
import { Field } from 'react-final-form'

export default props => {
    const { source } = props;
    return (
        <Field name={source} >
            {({ input, meta }) => {
                const deleteCallback = useCallback((evt) => {
                    evt.preventDefault();
                    const index = parseInt(evt.nativeEvent.target.id, 10);
                    input.onChange(input.value.filter((v,idx) => {
                        return idx !== index;
                    }));
                }, [ input ]);
                return (
                    <ul className="list-location">
                        {input.value.map((location, index) => {
                            return (
                                <li key={index}>
                                    {location.city} 
                                    <i id={index} className="icon icon-trash" onClick={deleteCallback}></i>
                                </li>
                            );
                        })}
                    </ul>
                );
            }}
        </Field>
    );
}