import * as React from "react";
import { Admin, Resource } from 'react-admin';
import DataProvider from './DataProvider';
import AuthProvider from './AuthProvider';
import Layout from './Layout/Layout';
import LoginPage from './Layout/LoginPage';
import LogoutButton from './Components/LogoutButton';

import LeadsList from './Components/LeadsList';
import LeadsEdit from './Components/LeadsEdit';
import LeadsCreate from './Components/LeadsCreate';

import LeadsSaleList from './Components/LeadsSaleList';
import LeadsRatingList from './Components/LeadsRatingList';
import LeadsFinancingList from './Components/LeadsFinancingList';

import AgentsList from './Components/AgentsList';

import WebsitesList from './Components/WebsitesList';
import WebsitesCreate from './Components/WebsitesCreate';

import './styles/concat.scss';
import config from "./config";
import PropTechsList from "./Components/PropTechs/PropTechsList";
import PropTechCreate from "./Components/PropTechs/PropTechCreate";
import PropTechEdit from "./Components/PropTechs/PropTechEdit";
import PropTechLeadsOverview from "./Components/PropTechs/PropTechLeadsOverview";
import PropTechLeadsList from "./Components/PropTechs/PropTechLeadsList";
import CreditLeadsList from "./Components/CreditLeadsList";
import LeadsCredit from "./Components/EditView/LeadsCredit";
import CreditCardLeadsList from "./Components/CreditCardLeadsList";
import LeadsCreditCard from "./Components/EditView/LeadsCreditCard";
import LeadsFindingList from "./Components/LeadsFindingList";
import Dashboard from "./Components/Dashboard";
import Summary from "./Components/Summary";

const dataProvider = DataProvider(config.API_URL, AuthProvider.getHttpClient());
const App = () => (
  <Admin
    dataProvider={dataProvider}
    authProvider={AuthProvider}
    layout={Layout}
    loginPage={LoginPage}
    logoutButton={LogoutButton}
  >
    <Resource name="dashboard" options={{ label: 'Dashboard' }} list={Dashboard} create={LeadsCreate} />
    <Resource name="leads/dashboard/summary" options={{label: 'Zusammenfassung'}} list={Summary} />

    <Resource name="stats" options={{ label: 'Statistik' }} />

    <Resource name="agents_type_estateAgent" options={{ label: 'Partner-Makler' }} list={AgentsList} />
    {
      /*
      TODO: Support path for type routes: agents_type_estateAgent/bills
      */
    }
    <Resource name="agents_type_estateAgent/bills" options={{ label: 'Rechnungen', childOf: 'agents_type_estateAgent' }} />

    <Resource name="leads_type_all" options={{ label: 'Leads' }} list={LeadsList} show={LeadsEdit} />
    <Resource name="leads_type_sale" options={{ label: 'Immobilienverkauf', childOf: 'leads_type_all' }} list={LeadsSaleList} show={LeadsEdit} />
    <Resource name="leads_type_rating" options={{ label: 'Immobilienbewertung', childOf: 'leads_type_all' }} list={LeadsRatingList} show={LeadsEdit} />
    <Resource name="leads_type_financing" options={{ label: 'Immobilienfinanzierung', childOf: 'leads_type_all' }} list={LeadsFinancingList} show={LeadsEdit} />
    <Resource name="leads_type_finding" options={{ label: 'Immobiliensuche', childOf: 'leads_type_all' }} list={LeadsFindingList} show={LeadsEdit} />

    <Resource name="leads_credits_leads" options={{ label: 'Kredite' }} list={CreditLeadsList} show={LeadsCredit} />
    <Resource name="leads_creditcards_leads" options={{ label: 'Kreditkarten', childOf: 'leads_credits_leads' }} list={CreditCardLeadsList} show={LeadsCreditCard} />

    <Resource name="status" options={{ label: 'Status' }} />
    <Resource name="leads_status_active" options={{ label: 'In Vermarktung', childOf: 'status' }} list={LeadsList} show={LeadsEdit} />
    <Resource name="leads_status_finished" options={{ label: 'Verkauft', childOf: 'status' }} list={LeadsList} show={LeadsEdit} />
    <Resource name="leads_status_noInterest" options={{ label: 'Kein Interesse', childOf: 'status' }} list={LeadsList} show={LeadsEdit} />


    <Resource name="agents_type_financeAgent" options={{ label: 'Finanzierer' }} list={AgentsList} />
    <Resource name="agents_type_financeAgent/bills" options={{ label: 'Rechnungen', childOf: 'agents_type_financeAgent' }} />

    <Resource name="proptechs" options={{ label: 'PropTechs' }} list={PropTechsList} create={PropTechCreate} show={PropTechEdit} />
    <Resource name="proptechs_leads_Homeday" options={{ label: 'Homeday', childOf: 'proptechs' }} list={PropTechLeadsOverview} show={PropTechLeadsList} />
    <Resource name="admin/websites" options={{ label: 'Webseiten', adminLink: true }} list={WebsitesList} create={WebsitesCreate} />

  </Admin>
);

export default App;