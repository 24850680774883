import React, { useState, useCallback, isValidElement, useEffect } from "react";
import { Form } from 'react-final-form'
import isEqual from 'lodash/isEqual'
import set from 'lodash/set'
import Ajv from 'ajv';
import addKeywords from 'ajv-keywords';
import addFormats from "ajv-formats";
import localize_de from 'ajv-i18n/localize/de';

const ajv = new Ajv({ allErrors: true, coerceTypes: true });
addKeywords(ajv, ['transform']);
addFormats(ajv);

export default props => {
    const { validationSchema, children, record, save, saving, redirect = 'edit' } = props;
    const [isUpdating, setIsUpdating] = useState(false);
    const [compiledSchema, setCompiledSchema] = useState(undefined);
    const saveCallback = useCallback((values) => {
        if (saving)
            return;
        setIsUpdating(true);
        save({ ...values }, redirect);
    }, [save, saving, redirect]);
    useEffect(() => {
        if (isUpdating && !saving) {
            setIsUpdating(false);
        }
    }, [saving, isUpdating, setIsUpdating]);
    useEffect(() => {
        if (!validationSchema)
            return;
        const cS = ajv.getSchema(validationSchema.$id) || ajv.compile(validationSchema);
        setCompiledSchema(() => cS);
    }, [validationSchema, setCompiledSchema]);
    const validationCallback = useCallback((values) => {
        const errors = {};
        if (!compiledSchema)
            return errors;
        const valid = compiledSchema(values);
        if (valid || !compiledSchema.errors)
            return errors
        localize_de(compiledSchema.errors);
        compiledSchema.errors.forEach(err => {
            if (err.keyword === 'required')
                set(errors, err.instancePath.substring(1).replace('/', '.') + '.' + err.params.missingProperty, err.message);
            else
                set(errors, err.instancePath.substring(1).replace('/', '.'), err.message);
        });
        return errors;
    }, [compiledSchema]);
    return (
            <Form
                onSubmit={saveCallback}
                initialValues={record ?? {}}
                initialValuesEqual={isEqual}
                keepDirtyOnReinitialize
                validate={validationSchema ? validationCallback : undefined}
                render={({ handleSubmit }) => {
                    return (
                        <form onSubmit={handleSubmit}>
                            {typeof children !== 'function' && React.Children.map(children, (field) =>
                                isValidElement(field) ? React.cloneElement(field, {
                                    isEditMode: true,
                                    record,
                                    saving
                                }) : null
                            )}
                            { typeof children === 'function' && children({
                                isEditMode: true,
                                record,
                                saving
                            })}
                        </form>
                    );
                }}
            />
    );
}