import React, { useCallback } from "react";
import { useTranslate, useLogout } from 'ra-core';
export default (props) => {
    const { redirectTo } = props;
    const translate = useTranslate();
    const logout = useLogout();
    const handleClick = useCallback((evt) => {
        evt.preventDefault();
        logout(redirectTo)
    }, [
        redirectTo,
        logout,
    ]);

    return (
        <button className="link-button" onClick={handleClick}>
            <div className="flex flex-center">
                <i className="icon icon-log-out"></i>
                <span>{translate('ra.auth.logout')}</span>
            </div>
            
        </button>
    )
}