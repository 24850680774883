import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import List from '../List';
import DataGrid from '../List/DataGrid';
import DateField from '../List/DateField';
import TextField from '../List/TextField';

const defaultSort = { field: 'createdAt', order: 'DESC' };

export default props => {
  const { basePath } = props;
  const history = useHistory();
  const addNewCallback = useCallback(() => {
    if (!history)
      return;
    history.push(`${basePath}/create`);
  }, [history, basePath]);

  return (
    <List {...props} legendData={[]} handleAdd={addNewCallback} sort={defaultSort} perPage={10}>
      <DataGrid rowClick="show">
        <DateField source="createdAt" label="Datum" notSearchable sortable />
        <TextField source="name" label="Name" />
        <TextField source="email" label="Email" />
      </DataGrid>
    </List>
  );
}