import React, { isValidElement } from "react";

export default props => {
    const { legendData, title, handleAdd } = props;
    return (
        <>
            <div className={'flex space' + (!title ? ' last' : '')}>
                {title && <h2 className="symbol">{title}</h2>}
                {handleAdd && <div className='flex'><button className="btn btn-border btn-no-margin" onClick={handleAdd}>Neu</button></div>}
                {legendData && legendData.length > 0 && (
                    <div className={'legend' + (!title ? ' big' : '')}>
                        <div className="legend-content">
                            {legendData && legendData.map(d => {
                                if (isValidElement(d)) {
                                    return 'Valid Element'
                                } else {
                                    return (
                                        <p key={d.label}>
                                            <span className={`status ${d.color}`}></span>
                                            {d.label}
                                        </p>
                                    );
                                }
                            })}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}