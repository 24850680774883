import React, { useState } from 'react';
import { ListContextProvider, Loading, useListContext, useQuery } from 'react-admin';
import { useHistory } from 'react-router-dom';

import keyBy from '../../utils/keyBy';
import TextField from '../List/TextField';
import StatusField from '../List/StatusField';
import DatagridHeaderCell from '../List/DatagridHeaderCell';
import DataGridRow from '../List/DataGridRow';

const PropTechDatagrid = (props) => {
  const history = useHistory();
  const listContext = useListContext();
  const { currentSort, basePath, ids, data } = listContext;
  const { children } = props;
  const resource = basePath.slice(1);

  const rowClick = (id) => {
    history.push(`/leads_type_all/${id}/show`);
  }

  const expand = null;
  const expandClass = "";
  return (
    <div className={"data-table"}>
      <table className={"dashboard"} id="table">
        <thead>
          <tr role="row">
            {React.Children.map(children, (field, index) =>
              React.isValidElement(field) ? (
                <DatagridHeaderCell
                  currentSort={currentSort}
                  field={field}
                  isSorting={
                    currentSort.field ===
                    (field.props.sortBy || field.props.source)
                  }
                  key={field.props.source || index}
                  resource={resource}
                  setSort={() => { }}
                  isSearchable={!field.props.notSearchable}
                  isSortable={!field.props.notSearchable || field.props.sortable}
                  filterValues={{}}
                  setFilters={() => { }}
                />
              ) : null
            )}
          </tr>
        </thead>
        <tbody>
          {ids.map((id, rowIndex) =>
            React.cloneElement(
              <DataGridRow />,
              {
                basePath,
                //hover,
                id,
                key: id,
                //onToggleItem,
                record: data[id],
                resource,
                rowClick,
                expand,
                expandClass
              },
              children
            )
          )}
        </tbody>
      </table>
    </div>
  );
}

export default props => {
  const [page, setPage] = useState(1);

  const perPage = 10;
  const basePath = `${props.basePath}_${props.id}`;
  const resource = basePath.slice(1);

  const query = useQuery({
    type: 'getList',
    resource,
    payload: {
      pagination: { page, perPage },
      sort: { field: 'createdAt', order: 'DESC' },
      filter: {}
    }
  });

  if (query.loading) return <Loading />;
  if (query.error) {
    console.error(query.error);
    return <p>Error!</p>
  }
  return (
    <ListContextProvider value={{
      ...query,
      setPage,
      basePath,
      data: keyBy(query.data, 'id'),
      ids: query.data.map(({ id }) => id),
      currentSort: { field: 'id', order: 'ASC' },
      selectedIds: [],
      resource,
      page,
      perPage,
    }}>
      <PropTechDatagrid>
        <StatusField source="" label="" notSearchable statusData={[]} />
        <TextField source="customer.firstName" label="Kontakt" />
        <TextField source="customer.lastName" label="Kontakt" />
        <TextField source="listing.city" label="Stadt" />
        <TextField source="listing.postalCode" label="PLZ" />
      </PropTechDatagrid>
    </ListContextProvider>
  );
}