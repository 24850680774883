import React from "react";
import { useHistory } from 'react-router-dom';
import { useCreateController } from 'react-admin';

import PropTechCreateSchema from './schema/PropTechCreate.json';
import ImmoForm from "../Form/ImmoForm";
import SubmitButtonLeft from "../Form/SubmitButtonLeft";
import TextInputField from "../Form/TextInputField";

const CreatePropTech = props => {
  return (
    <ImmoForm {...props} validationSchema={PropTechCreateSchema}>
      {(formProps) => {
        return (
          <div className="flex row">
            <div className="flex-item">
              <div className={"shadow-box overview"}>
                <h3 className="underlined">Übersicht</h3>
                <div className="details-info">
                  <TextInputField source="name" label="Name" {...formProps} />
                  <TextInputField source="email" label="E-Mail-Adresse" {...formProps} />
                </div>
              </div>
            </div>
            <div className="flex-item">
              <SubmitButtonLeft {...formProps} />
            </div>
          </div>
        );
      }}
    </ImmoForm>
  );
}

export default props => {
  const history = useHistory();
  const createProps = useCreateController({ ...props, undoable: false, redirect: 'show' });
  return (
    <div>
      <div className="flex space">
        <h2>PropTech Erstellen</h2>
        <button onClick={history.goBack} className="btn btn-border"><i className="icon icon-chevron-left"></i>Zurück</button>
      </div>
      <CreatePropTech {...createProps} />
    </div>
  );
}