import React from "react";
import { useEditController } from 'react-admin';
import ImmoForm from '../Form/ImmoForm';
import TextInputField from '../Form/TextInputField';
import SubmitButton from '../Form/SubmitButton';

import websiteCreateSchema from './schema/WebsiteCreate.json';

const generateDNSEntries = record => {
    const result = 
    `TXT     _amazonses.${record.domain}     ${record.ses.VerificationToken}
    
CNAME   ${record.ses.DkimTokens[0]}._domainkey.${record.domain}     ${record.ses.DkimTokens[0]}.dkim.amazonses.com
CNAME   ${record.ses.DkimTokens[1]}._domainkey.${record.domain}     ${record.ses.DkimTokens[1]}.dkim.amazonses.com
CNAME   ${record.ses.DkimTokens[2]}._domainkey.${record.domain}     ${record.ses.DkimTokens[2]}.dkim.amazonses.com
    `;

    return result;
}

const generateAPITokenText = record => {
    const result = `${record.currentApiToken.token}`;

    return result;
}

export default (props) => {
    const editProps = useEditController({ ...props, undoable: false });
    const { record } = props;
    return (
        <td colSpan="9">
            <ImmoForm
            {...editProps}
            validationSchema={websiteCreateSchema}
            >
                {(formProps) => {
                    return (
                        <div className="flex row">
                            <div className="headline">
                                <h2>Webseiten-Details ansehen/bearbeiten</h2>
                                <hr className="line" />
                            </div>
                            <div className="flex-item-33">
                                <TextInputField source="domain" label="Domain" {...formProps} disabled />
                            </div>
                            <div className="flex-item-33">
                            <TextInputField source="google.tid" label="Google TrackingID" {...formProps} />
                            </div>
                            <div className="flex-item-33">
                                
                            </div>
                            <div className="interactions">
                                <div className="flex">
                                    <h4>API Key</h4>
                                    <textarea 
                                        className="small" 
                                        spellCheck="false"
                                        readOnly
                                        value={generateAPITokenText(record)} 
                                    />
                                </div>
                            </div>
                            <div className="interactions">
                                <div className="flex">
                                    <h4>DNS Einträge</h4>
                                    <textarea 
                                        spellCheck="false"
                                        readOnly
                                        value={generateDNSEntries(record)}
                                    />
                                </div>
                                
                            </div>
                            <div className="interactions">
                                <SubmitButton buttonClassName="btn" {...formProps}/>
                            </div>
                        </div>
                    );
                }}
            </ImmoForm>
        </td>
    )
}

/*

 <tr class="edit-broker">
                    <td colspan="9">
                        <div class="flex row">
                            <div class="headline">
                                <h2>Makler-Details ansehen/bearbeiten</h2>
                                <hr class="line">
                            </div>
                            <div class="flex-item-33">
                                <label>
                                    Name des Maklers
                                    <input type="text">
                                </label>
                                <label>
                                    Stadt
                                    <input type="text">
                                </label>
                                <label>
                                    PLZ
                                    <input type="number">
                                    <span class="error">Bitte geben Sie eine gültige PLZ ein.</span>
                                </label>
                                <label>
                                    Straße, Hausnr.
                                    <input type="text">
                                </label>
                                <label class="location">
                                    Weitere Standorte
                                    <input type="text" placeholder="Standort hinzufügen">
                                    <input class="btn-add" type="submit" value="+">
                                </label>

                                <ul class="list-location">
                                    <li class="remove">Bückeburg <i class="icon icon-trash"></i></li>
                                    <li>Hildesheim <i class="icon icon-trash"></i></li>
                                </ul>
                            </div>
                            <div class="flex-item-33">
                                <label>
                                    E-Mail-Adresse
                                    <input type="email">
                                </label>
                                <label>
                                    Telefonnummer
                                    <input type="text">
                                    <span class="error">Bitte geben Sie eine gültige Telefonnummer ein.</span>
                                </label>
                                <label>
                                    Webseite
                                    <input type="text">
                                </label>
                                <label>
                                    Inhaber / Geschäftsführer
                                    <input type="text">
                                </label>
                                <label>
                                    Vertrieb durch
                                    <label class="custom-select">
                                        <select>
                                            <option disabled></option>
                                            <option>M. Bürst</option>
                                            <option>M. Dwornitschak</option>
                                            <option selected>C. Wiese</option>
                                        </select>
                                    </label>
                                </label>

                            </div>
                            <div class="flex-item-33">
                                <h3 class="personal-headline">Persönlicher Kontakt</h3>

                                <label>
                                    Name der Kontaktperson
                                    <input type="text">
                                </label>
                                <label>
                                    PLZ
                                    <input type="number">
                                </label>
                                <label>
                                    Stadt
                                    <input type="text">
                                </label>
                                <label>
                                    Straße, Hausnr.
                                    <input type="text">
                                </label>

                                <div>
                                    <p>Status des Maklers</p>
                                    <div class="radio">
                                        <input id="active" name="radio" type="radio" checked>
                                        <label for="active" class="radio-label">aktiv</label>
                                    </div>
                                    <div class="radio">
                                        <input id="inactive" name="radio" type="radio">
                                        <label for="inactive" class="radio-label">inaktiv</label>
                                    </div>
                                    <div class="radio">
                                        <input id="block" name="radio" type="radio">
                                        <label for="block" class="radio-label">sperren</label>
                                    </div>
                                    <label class="block">
                                        Grund für Sperrung
                                        <input type="text">
                                    </label>
                                </div>

                            </div>

                            <div class="interactions">
                                <button class="delete">Makler löschen</button>
                                <div class="loader">
                                    <img src="/img/loader.gif" alt="Ladebalken" title="Lädt" class="loader">
                                </div>
                                <input class="btn" type="submit" value="Änderungen übernehmen">
                            </div>
                        </div>
                    </td>
                </tr>

*/