import React, { useState } from 'react';
import { useLogin, useNotify, Notification } from 'react-admin';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({});

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const login = useLogin();
    const notify = useNotify();
    const submit = (e) => {
        e.preventDefault();
        login({ email, password })
            .catch(() => notify('Invalid email or password'));
    };
    return (
        <ThemeProvider theme={theme}>
            <div className="login">
                <div className="flex">
                    <div className="left">
                        <img className="logo" src="/img/immolead.svg" alt="Logo von ImmoLead" title="ImmoLead" />
                        <h1>Herzlich willkommen im ImmoLead Backend.</h1>
                    </div>
                    <div className="right">
                        <h2>Einloggen</h2>
                        <form method="post">
                            <label>
                                E-Mail
                                <input name="email" type="email" value={email} onChange={e => setEmail(e.target.value)} />
                            </label>
                            <label>
                                Passwort
                                <input name="password" type="password" value={password} onChange={e => setPassword(e.target.value)} />
                            </label>
                            <button onClick={submit} className="btn">Einloggen</button>
                        </form>
                    </div>
                </div>
                <Notification />
            </div>
        </ThemeProvider>
    );
};

export default LoginPage;


/*

<div class="login">
    <div class="flex">
        <div class="left">
            <img class="logo" src="/img/immolead.svg" alt="Logo von ImmoLead" title="ImmoLead">

            <h1>Herzlich willkommen im ImmoLead Backend.</h1>

        </div>
        <div class="right">
            <h2>Einloggen</h2>
            <form method="post">
                <label>
                    Benutzername
                    <input type="text">
                </label>

                <label>
                    Passwort
                    <input type="password">
                </label>
                <a href="/" class="btn">Einloggen</a>
                <!--<input type="submit" class="btn" value="Einloggen">-->
            </form>

        </div>
    </div>
</div>

*/