import React from "react";
import get from 'lodash/get';

export default ({ record, source, showExapandButton, handleToggleExpand, showLP = false }) => {
  const value = get(record, source);
  let lp = showLP === true ? get(record, 'lp') : '';
  if (lp == null && value === "kredit.de") {
    lp = "A";
  }
  return (<>
    {value}{lp != null && ` Variante ${lp}`}
    {showExapandButton && handleToggleExpand && (
      <i className="icon icon-eye" onClick={handleToggleExpand}></i>
    )}
  </>);
}