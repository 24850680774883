import React from "react";
import has from 'lodash/has';

const comparePS = (rules, values, and) => and ? rules.every(r => values.includes(r)) : rules.some(r => values.includes(r));

export default ({ record, statusData }) => {
    const status = record?.listing?.status ?? 'open';
    const processingSteps = record?.listing?.processingSteps ?? [];
    let sD = {
        color: 'gray',
        label: 'default'
    };
    for (const d of statusData) {
        if (d.status && !d.status.includes(status))
            continue;

        if (d.processingSteps && !comparePS(d.processingSteps, processingSteps, d.and))
            continue;

        if (d.notProcessingSteps && comparePS(d.notProcessingSteps, processingSteps, d.and))
            continue;

        sD = d;
        break;
    }
    // FIXME this should be done in immolead-api and not here ..
    const proptech = has(record, "propTechDetails.propTech");
    if(proptech) {
        const processed = statusData.find(data => data.label === "vermittelt");
        if(processed) {
            sD = processed;
        }
    }
    return <span className={`status ${sD.color}`} title={sD.label + " - " + status}></span>
}