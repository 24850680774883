import React, { Fragment } from "react";
import { useDeleteWithConfirmController } from 'react-admin';

import ConfirmDialog from './ConfirmDialog';

export default (props) => {
    const {
        basePath,
        confirmTitle = 'Wirklich löschen?',
        confirmText = 'Sind sie sich sicher, dass Sie diesen Datensatz löschen möchten?',
        confirmOk = 'Endgültig löschen',
        confirmCancel = 'Abbrechen',
        onClick,
        record,
        resource,
        redirect = 'list'
    } = props;

    const {
        open,
        loading,
        handleDialogOpen,
        handleDialogClose,
        handleDelete,
    } = useDeleteWithConfirmController({
        resource,
        record,
        redirect,
        basePath,
        onClick,
    });
    return (
        <Fragment>
            <button className="delete" onClick={handleDialogOpen}>Löschen</button>
            <ConfirmDialog
                isOpen={open}
                loading={loading}
                title={confirmTitle}
                text={confirmText}
                confirmOk={confirmOk}
                confirmCancel={confirmCancel}
                type='delete'
                onConfirm={handleDelete}
                onClose={handleDialogClose}
            />
        </Fragment>
    );
}