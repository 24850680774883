import config from './config';
import { fetchUtils } from 'react-admin';

export default {
    login: ({ email, password }) => {
        const request = new Request(config.API_URL + '/user/login', {
            method: 'POST',
            body: JSON.stringify({ email, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ status, data }) => {
                if (status === 'ok' && data.token)
                    localStorage.setItem(config.AUTH_TOKEN_KEY, data.token);
            });
    },
    logout: () => {
        const token = localStorage.getItem(config.AUTH_TOKEN_KEY);
        if (!token)
            return Promise.resolve();
        const request = new Request(config.API_URL + '/user/logout', {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ status }) => {
                if (status === 'ok')
                    localStorage.removeItem(config.AUTH_TOKEN_KEY);
                else
                    throw new Error("Logout not OK!");
            });
    },
    checkAuth: () => localStorage.getItem(config.AUTH_TOKEN_KEY) ? Promise.resolve() : Promise.reject(),
    checkError: error => {
        const status = error.status;
        if (status === 401 || status === 403) {
            localStorage.removeItem(config.AUTH_TOKEN_KEY);
            return Promise.reject();
        }
        return Promise.resolve();
    },
    getPermissions: () => {
        const token = localStorage.getItem(config.AUTH_TOKEN_KEY);
        if (!token)
            return Promise.reject();
        const jwtParts = token.split('.');
        if (!jwtParts || jwtParts.length !== 3)
            return Promise.reject();
        const payload = JSON.parse(atob(jwtParts[1]));
        if (!payload || !payload.scope)
            return Promise.reject();
        return Promise.resolve(payload.scope.split(" "));
    },
    getHttpClient: () => {
        return (url, options = {}) => {
            if (!options.headers) {
                options.headers = new Headers({ Accept: 'application/json' });
            }
            const token = localStorage.getItem(config.AUTH_TOKEN_KEY);
            if (token)
                options.headers.set('Authorization', `Bearer ${token}`);
            options.allowGetBody = true;
            return fetchUtils.fetchJson(url, options).then(({ headers, json }) => {
                const newToken = headers.get('x-token');
                if (newToken)
                    localStorage.setItem(config.AUTH_TOKEN_KEY, newToken);
                return { headers, json };
            });
        };
    }
};