import React, { useCallback } from "react";
import { useHistory } from 'react-router-dom';
import { useEditController } from 'react-admin';


import DeleteButton from '../DeleteButton';
import DetailForm from "../Form/DetailForm";
import TextInputField from "../Form/TextInputField";
import ContactFooterInlineUtm from "./ContactFooterInlineUtm";
import SelectInputField from "../Form/SelectInputField";
import MailStatus from "../Form/MailStatus";
import DateInputField from "../Form/DateInputField";
import NumberInputField from "../Form/NumberInputField";
import ChildSupport from "../Form/ChildSupport";
import BoolField from "../Form/BoolField";


export const genderTypes = {
    'Male': 'Herr',
    'Female': 'Frau',
    'Diverse': 'Divers',
};


const T = props => {
    return (
        <>
            <div className="flex row">
                <div className="flex-item">
                    <DetailForm
                        formLabel="Kontaktdaten"
                        boxClassName="contact"
                        {...props}
                    >
                        <SelectInputField source="lead.gender" label="Geschlecht" options={genderTypes} />
                        <TextInputField source="lead.firstname" label="Vorname" />
                        <TextInputField source="lead.surname" label="Nachname" />
                        <TextInputField source="lead.street" label="Straße" />
                        <TextInputField source="lead.streetnr" label="Hausnummer" />
                        <TextInputField source="lead.postalcode" label="Postleitzahl" />
                        <TextInputField source="lead.city" label="Stadt" />
                        <TextInputField source="lead.country" label="Land" />
                        <TextInputField source="lead.email" label="E-Mail-Adresse" />
                        <TextInputField source="lead.phone" label="Telefonnummer" />
                        <TextInputField source="lead.birth_location" label="Geburtsort" />
                        <DateInputField source="lead.birth_date" label="Geburtstag" />
                        <TextInputField source="lead.schufa" label="Schufa Zustimmung" />
                        <ContactFooterInlineUtm />
                    </DetailForm>
                </div>
                <div className="flex-item">
                    <DetailForm
                        formLabel="Lead Status"
                        boxClassName="overview"
                        {...props}
                    >
                    <TextInputField source="success" label="Übermittlung erfolgreich" />
                    <TextInputField source="responseData.decision" label="Entscheidung" />
                    <TextInputField source="responseData.message" label="Nachricht" />
                    <TextInputField source="responseData.customerToken" label="Token" />
                    <TextInputField source="responseData.status" label="HTTP Status" />
                    <TextInputField source="status" label="Interner Status" />
                    <TextInputField source="scheduledAt" label="In Warteschalnge aufgenommen" />
                    <TextInputField source="runningAt" label="Wird verarbeitet" />
                    <TextInputField source="finishedAt" label="Verarbeitung abgeschlossen" />
                    <TextInputField source="statusReceivedAt" label="Status abgerufen" />
                    </DetailForm>
                </div>
            </div>
            <div className="flex row">
                <div className="flex-item">
                    <DetailForm
                        formLabel="Beschäftigung"
                        boxClassName="overview"
                        {...props}
                    >
                        <TextInputField source="lead.employment_type" label="Tätigkeit" />
                        <DateInputField source="lead.employee_employed_since" label="Beschäftigt seit" />
                        <NumberInputField source="lead.employee_netto" label="Nettoeinkommen" />
                    </DetailForm>
                </div>
            </div>
        </>
    );
}

export default props => {
    const history = useHistory();
    const editProps = useEditController({ ...props, undoable: false });
    const exportCallback = useCallback(() => {
        const record = editProps.record;
        if(!record) return;
        const json = JSON.stringify(record, null, 2);
        // eslint-disable-next-line no-undef
        const dlElement = document.createElement('a');
        dlElement.href = `data:application/json;charset=utf-8,${encodeURI(json)}`;
        dlElement.target = '_blank';
        dlElement.download = `lead-${record.id}.json`;
        dlElement.click();
    }, [editProps]);

    return (
        <div className={'details'}>
            <div className="flex space">
                <h2 className="symbol">Lead</h2>
                <button onClick={history.goBack} className="btn btn-border"><i className="icon icon-chevron-left"></i>Zurück</button>
            </div>
            <T {...editProps} />
            <button className="export" onClick={exportCallback}>Export</button>
            {editProps.record && <DeleteButton {...editProps}/>}
        </div>
        
    );
}